
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const Login = () => {
  return (
    <>
      <footer className="py-4">
        <Container fluid>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright outer-page text-center text-xl-left clr-white">
              Copyright © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1 clr-white"
                  href="https://www.creative-tim.com?ref=adr-auth-footer"
                  target="_blank"
                >
                Charitex. All rights reserved.
                </a>
              </div>
            </Col>
           
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
