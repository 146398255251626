import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  DropdownToggle,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  Input,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import PaginatedTable from "../../components/Table/PaginatedTable";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  capitalizeFirstLetter,
  formateDate,
  formateDatemdy,
} from "../../utils/Helper";
// import userimg from "../../assets/img/icons/userDefault.png";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import useMenuPermissions from "../../components/Customhook/custompermission";
import Notauthorized from "../../components/Not-authorized/notauthorized";
import Papa from "papaparse";


const Donorlist = () => {
  const navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken,logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };
  const [limit, setLimit] = useState(10);
  const [categories, setCategories] = useState([]);
  const [data1, setData1] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [count,setCount]=useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [roleid, setRoleid] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRange, setSelectedRange] = useState("");
  const [reset, setReset] = useState(false);
  // const [showDt, setShowDt] = useState(false);
  // const [alertOpen, setAlertOpen] = useState(false);
  // const [selectedFaq, setSelectedFaq] = useState(null);
  // const [showStatusModal, setShowStatusModal] = useState(false);
  const dropdownRef = useRef(false);
  const statusRef = useRef("Select Status");
  const permissionscontext = useMenuPermissions('donors');

  const toggleDropdown = () => {
    dropdownRef.current = !dropdownRef.current;
    setData1((prev) => [...prev]);
  };

  const fetchData = async (page = 1) => {
    const skip = page;
    const encodedStatus =
      statusRef.current === "Select Status" ? "" : statusRef.current;
    try {
      // &offset=${skip}&limit=${limit}
      const queryParams = [];

      if (fullname) queryParams.push(`name=${fullname}`);
      if (email) queryParams.push(`email=${email}`);
      if (roleid) queryParams.push(`category_id=${roleid}`);
      if (phoneno) queryParams.push(`phone_number=${phoneno}`);
      if (startDate) queryParams.push(`from_date=${formateDate(startDate)}`);
      if (endDate) queryParams.push(`to_date=${formateDate(endDate)}`);
      if (limit) queryParams.push(`limit=${limit}`);
      if (skip) queryParams.push(`offset=${skip}`);
      if (encodedStatus) queryParams.push(`status=${encodedStatus}`);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
      const response = await APIServices.get(
        `/admin/donor/list${queryString}`,
        headers
      );
      if (response?.status === 200) {
        const data1 = response?.data?.list || [];
        setData1(data1);
        setCount(response?.data?.count)
        const totalItems = response?.data?.count || 0;
        setTotalCount(Math.ceil(totalItems / limit));
      } else if(response?.status === 401){
        logout();
        navigate("/");
       }else {
        setData1([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData1([]);
      setTotalCount(0);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await APIServices.get(
        `/admin/donor/category_list`,
        headers
      );
      const data = await response?.data?.list;
      setCategories(data);
      if (response?.status === 200) {
        const data = response?.data?.list || [];
        setCategories(data);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, limit]);

  const handleSearch = () => {
    fetchData();
  };
  const handleSelect = (status) => {
    statusRef.current = status;
  };

  const refreshData = () => {
    setCurrentPage(1);
    fetchData(1);
  };


  const handleStatusChange = async (faq) => {
    const newStatus = faq.status === "Active" ? "Inactive" : "Active";
    try {
      const payload = { id: faq.id, status: newStatus };
      const res = await APIServices.post(
        `/admin/donor/donor_status_update`,
        payload,
        headers
      );
      if (res?.data?.status === true) {
        showToast(res?.data?.message || "Success", "success");
        const updatedFaqs = data1.map((item) =>
          item.id === faq.id ? { ...item, status: newStatus } : item
        );
        setData1(updatedFaqs);
      } else {
        showToast(res?.data?.message || "Something went wrong.", "error");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      showToast("Error updating status", "error");
    }
  };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  const columns = [
    {
      dataField: "index",
      text: "S.No.",
      formatter: (cell, row, rowIndex) => {
        const page = currentPage; // Replace with the actual way to get the current page number
        const sizePerPage = limit; // Replace with the actual way to get rows per page
        return (page - 1) * sizePerPage + rowIndex + 1;
      },
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (cell, row) =>
        capitalizeFirstLetter(row.first_name) + " " + row?.last_name,
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell, row) => row.email,
    },
    {
      dataField: "category_name",
      text: "Category Name",
      formatter: (cell, row) => row.category_name,
    },
    {
      dataField: "phone_number",
      text: "Mobile",
      formatter: (cell, row) => {
        if (row?.phone_code) {
          return `${row.phone_code}-${row.phone_number}`;
        }
        return row.phone_number;
      },
    },
    {
      dataField: "Registered",
      text: "Registered",
      formatter: (cell, row) => formateDatemdy(row.created),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <Button
          color={row.status === "Active" ? "success" : "warning"}
          onClick={() => handleStatusChange(row)}
          size="sm"
          className=""
          disabled={!permissionscontext.edit}
        >
          {row.status === "Active" ? "Active" : "Inactive"}
        </Button>
      ),
    },
  ];

  const handleReset = () => {
    setFullname("");
    setEmail("");
    setPhoneno("");
    setRoleid("");
    setReset(true);
    setStartDate(null);
    setEndDate(null);
    setSelectedRange("");
    handleSelect("Select Status");
  };

  useEffect(() => {
    if (reset) {
      fetchData();
      setCurrentPage(1)
      setReset(false);
    }
  }, [reset]);

  const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };



  const handleExportCSV = async() => {
    const offset = 0;
    const encodedStatus =
    statusRef.current === "Select Status" ? "" : statusRef.current;
    const queryParams = [];

      if (fullname) queryParams.push(`name=${fullname}`);
      if (email) queryParams.push(`email=${email}`);
      if (roleid) queryParams.push(`category_id=${roleid}`);
      if (phoneno) queryParams.push(`phone_number=${phoneno}`);
      if (startDate) queryParams.push(`from_date=${formateDate(startDate)}`);
      if (endDate) queryParams.push(`to_date=${formateDate(endDate)}`);
      if (limit) queryParams.push(`limit=${10000}`);
      if (offset) queryParams.push(`offset=${offset}`);
      if (encodedStatus) queryParams.push(`status=${encodedStatus}`);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
      const response = await APIServices.get(
        `/admin/donor/list${queryString}`,
        headers
      );
      console.log(response);
      if (response?.status === 200) {
    const csvData = response?.data?.list.map((item, index) => ({
      "S.No": index + 1,
      "Name": capitalizeFirstLetter(`${item.first_name} ${item.last_name}`),
      "Email": item.email,
      "Category Name": capitalizeFirstLetter(item.category_name),
      "Phone":item?.phone_code ? `${item.phone_code}-${item.phone_number}` : item?.phone_number,
      "REGISTERED ": formateDatemdy(item.created),
      "Status": item.status,
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "Donor list.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  if(response?.status === 401){
    logout();
    navigate("/");
   }

  };


  return (
    <>
    {permissionscontext.view ? <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0 card-title">Donor List <small>({count})</small></h3>
                  </div>
                </Row>
              </CardHeader>
              <div className="select-filter-wrapper  mb-0">
                <Input
                  className="w-25"
                  placeholder="Search by name"
                  value={fullname}
                  type="text"
                  onChange={(e) => setFullname(e.target.value)}
                />
                <Input
                  className="w-25"
                  placeholder="Search by email"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  className="w-25"
                  placeholder="Search by phone number"
                  value={phoneno}
                  type="text"
                  maxLength={10}
                  onChange={(e) => setPhoneno(e.target.value)}
                />
                <select
                  className="select-input"
                  value={roleid}
                  onChange={(e) => setRoleid(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.title}
                    </option>
                  ))}
                </select>
                
              </div>
              <div className="select-filter-wrapper">
              <ButtonDropdown
                className="dropdown-btn-wrapper"
                  isOpen={dropdownRef.current}
                  toggle={toggleDropdown}
                >
                  {/* className="btn-default" */}
                  <DropdownToggle  className="dropdown-btn select-input" caret>{statusRef.current}</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleSelect("Select Status")}>
                      Select Status
                    </DropdownItem>
                    <DropdownItem onClick={() => handleSelect("Active")}>
                      Active
                    </DropdownItem>
                    <DropdownItem onClick={() => handleSelect("Inactive")}>
                      Inactive
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
                <select
                  className="select-input"
                  value={selectedRange}
                  onChange={(e) => handleDateRange(e.target.value)}
                >
                  <option value="disabled">Select Date Range</option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="thisWeek">This Week</option>
                  <option value="last7Days">Last 7 Days</option>
                  <option value="thisMonth">This Month</option>
                  <option value="lastMonth">Last Month</option>
                  <option value="customDate">Custom Date</option>
                </select>

                {selectedRange === "customDate" && (
                  <DatePicker
                    className="select-input"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date()}
                    onChange={(update) => {
                      const [start, end] = update;
                      setStartDate(start);
                      setEndDate(end);
                    }}
                    // isClearable={true}
                    placeholderText="Select custom date range"
                    dateFormat="yyyy-MM-dd"
                  />
                )}
               
                <Button
                  color="primary"
                  onClick={(e) => refreshData(e)}
                  size="md"
                  className="filter mr-0" 
                >
                  <i className="fa fa-filter"></i> Filter
                  {/* <i className="fa fa-refresh fa-spin"></i>  */}
                </Button>
                <Button
                  color="light"
                  onClick={(e) => handleReset(e)}
                  size="md"
                  className="reset mx-0"
                >
                  <i className="fa fa-reset"></i> Reset
                </Button>
                <Button
                 className="export btn-grey ml-0"
                 onClick={handleExportCSV}
                 size="md"
                 disabled={data1.length == 0}
               >
                 Export CSV
               </Button>
              </div>
              {data1 && Array.isArray(data1) && data1.length !== 0 ? (
                <PaginatedTable
                  data={data1}
                  columns={columns}
                 
                  totalPages={totalCount}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                <h2 className="text-center mb-4">Not Found..</h2>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
      </>: (
       <Notauthorized />
      )}
    </>
  );
};

export default Donorlist;
