import React from 'react'
import Header from '../Headers/Header'
import { Card, Col, Container, Row } from 'reactstrap'

export default function Notauthorized() {
  return (<>
    <Header />
    <Container className="mt--7" fluid>
      <Row className="mt-5">
        <Col className="mb-5 mb-xl-0" xl="12">
          <Card className="shadow card_border not-auth-pg">
            Not Authorized
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  )
}
