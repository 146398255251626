import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  FormFeedback,
} from "reactstrap";
import APIServicenew from "../../utils/APIGeneralService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/img/icons/charitex.png";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const headers = { "auth-token": authToken };

  const validate = () => {
    const errors = {};
    if (!email) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    if (validate()) {
      const res = await APIServices.post(
        "admin/auth/forgot_password",
        {
          email,
        },
        headers
      );
      if (res?.data?.status === true) {
        setLoad(false);
        showToast(res?.data?.message || "Success", "success");
        setTimeout(() => {
          navigate("/reset-password");
        }, 2000);
      } else if (res?.data?.status === false) {
        console.log("error1");
        showToast(res?.data?.message || "Something went wrong.", "error");
        setLoad(false);
      } else {
        console.log("error");
        showToast("Something went wrong.", "error");
        setLoad(false);
      }
    }
    setLoad(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary login-card shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center mb-3">
              <img alt="logo" className="navbar-brand-img logo" src={logo} />
            </div>
            <div className="text-center text-muted mb-4">
              <h1 className="form-title1">Forgot Password</h1>
            </div>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </InputGroup>
                {errors.email && (
                  <div className="errorVal mt-2">{errors.email}</div>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  className="mt-4"
                  color="primary"
                  type="submit"
                  disabled={load}
                  tabIndex={0}
                >
                  {load ? "Processing..." : "Send"}
                </Button>
              </div>
              <div
                className="text-center my-2 pointer"
                onClick={() => navigate("/")}
                tabIndex={0}
              >
                Back to 
                <span style={{color:"blue"}}> Login</span>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
      <ToastContainer />
    </>
  );
};

export default ForgetPassword;
