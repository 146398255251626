import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContextProvider";
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";



const RequireAuth = ({ children }) => {
  const { accessToken } = useContext(UserContext);

  if (!accessToken) {
    return <Navigate to="/" replace/>;
  }
  

  return children;
};

const AppRouter = () => {
  const { accessToken } = useContext(UserContext);
  useEffect(() => {
    document.title = "Charitex Admin";
  }, []);


  return (
    <Routes>
        <Route path="/admin/*" element={<RequireAuth ><AdminLayout /></RequireAuth>} />
        <Route path="/*" element={accessToken ? <Navigate to="/admin" replace /> : <AuthLayout />} />
    </Routes>
  );
};

export default AppRouter;


