import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardHeader, Container, Row, Col, Table } from "reactstrap";
import Header from "../../components/Headers/Header";
import { UserContext } from "../../context/UserContextProvider";
import CustomAlert from "../../components/Alert/CustomAlert";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { capitalizeFirstLetter } from "../../utils/Helper";
import useMenuPermissions from "../../components/Customhook/custompermission";
import Notauthorized from "../../components/Not-authorized/notauthorized"; 

const Staticpage = () => {
  const navigate = useNavigate();
  const { accessToken } = useContext(UserContext);
  const authToken = process.env.REACT_APP_authToken;
  const headers = { "auth-token": authToken, authorization: accessToken };
  const permissionscontext = useMenuPermissions("pages");

  const [data1, setData1] = useState([
    { id: 1, title: "Privacy policy", Action: "Active" },
    { id: 2, title: "About", Action: "Inactive" },
    { id: 3, title: "Terms and Conditions", Action: "Inactive" },
  ]);

  const [alertOpen, setAlertOpen] = useState(false);

  const handleButtonClick = (title) => {
    switch (title.toLowerCase()) {
      case "privacy policy":
        navigate("/admin/pages/privacy-form");
        break;
      case "about":
        navigate("/admin/pages/about-form");
        break;
      case "terms and conditions":
        navigate("/admin/pages/term-form");
        break;
      default:
        break;
    }
  };

  return (
    <>
     {permissionscontext.view ? <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0 card-title">Page</h3>
                  </div>
                </Row>
              </CardHeader>
              {data1 && Array.isArray(data1) && data1.length !== 0 ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Title</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data1.map((row, index) => (
                      <tr key={row.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{capitalizeFirstLetter(row.title)}</td>
                        
                        <td>
                          <Button
                            color="light"
                            onClick={() => handleButtonClick(row.title)}
                            size="sm"
                            className="btn-theme1"
                            disabled={!permissionscontext.edit}
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h2 className="text-center mb-4">Not Found..</h2>
              )}
            </Card>
          </Col>
        </Row>
        <CustomAlert
          isOpen={alertOpen}
          toggle={() => setAlertOpen(false)}
          color="success"
          message="Action Successful"
        />
      </Container>
      <ToastContainer />
      </>: (
       <Notauthorized />
      )}
    </>
  );
};

export default Staticpage;
