import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIServicenew from "../utils/APIGeneralService";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  FormFeedback,
} from "reactstrap";
import { setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/img/icons/charitex.png";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { setAccessToken } = useContext(UserContext);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const headers = { "auth-token": authToken };

  const validate = () => {
    const errors = {};
    if (!username) {
      errors.email = "Email/Username is required.";
    }
    // else if (!/\S+@\S+\.\S+/.test(email)) {
    //   errors.email = 'Email is invalid.';
    // }
    if (!password) {
      errors.password = "Password is required.";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters.";
    }else if (password.length > 20) {
      errors.password = "Password must not exceed 20 characters.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    if (validate()) {
      const res = await APIServices.post(
        "admin/auth/login",
        {
          username,
          password,
        },
        headers
      );
      if (res?.data?.status === true) {
        setLoad(false);
        let accessToken = res?.data?.detail?.token;
        setCookies("accessToken", accessToken);
        setCookies("user_type", res.data?.detail?.user_type);
        setCookies("role_id", res.data?.detail?.role_id);
        setCookies("name", res.data?.detail?.name);
        setAccessToken(accessToken);
        if (rememberMe) {
          localStorage.setItem("token", res?.data?.detail?.token);
        }
        // refreshToken(accessToken);
        showToast(res?.data?.message || "Success", "success");
        setTimeout(() => {
          navigate("/admin/index");
        }, 2000);
      } else if (res?.data?.status === false) {
        console.log("error1");
        showToast(res?.data?.message || "Something went wrong.", "error");
        setLoad(false);
      } else {
        console.log("error");
        showToast("Something went wrong.", "error");
        setLoad(false);
      }
    }
    setLoad(false);
  };

  const handleEmailChange = (e) => {
    setUsername(e.target.value);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, username: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (errors.password) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center mb-3">
              <img alt="logo" className="navbar-brand-img logo" src={logo} />
            </div>
            <div className="text-center text-muted mb-4">
              <h1 className="form-title1 clr-navy">Sign in </h1>
            </div>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email/Username"
                    type="text"
                    autoComplete="new-email"
                    value={username}
                    onChange={handleEmailChange}
                  />
                </InputGroup>
                {errors.email && (
                  <div className="errorVal mt-2">{errors.email}</div>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    maxLength={20}
                    onChange={handlePasswordChange}
                  />
                </InputGroup>
                {errors.password && (
                  <div className="errorVal mt-2">{errors.password}</div>
                )}
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox d-flex justify-content-between ">
                <div>
                  <input
                    className="custom-control-input"
                    id="customCheckLogin"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label
                    className="custom-control-label checkbox-label"
                    htmlFor="customCheckLogin"
                  >
                    <span className="font_14 clr-navy">Remember me</span>
                  </label>
                </div>
                <span
                  className="cursor font_14 clr-navy"
                  onClick={() => navigate("/forget-password")}
                >
                  Forgot password ?
                </span>
              </div>
              <div className="text-center">
                {load ? (
                  <Button className="mt-4" color="primary">
                    Processing...
                  </Button>
                ) : (
                  <Button className="mt-4" color="primary" type="submit">
                    Sign in
                  </Button>
                )}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
      <>
        <ToastContainer />
      </>
    </>
  );
};

export default Login;
