// FaqForm.js
import { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Form, FormGroup, Input, Container, Label } from "reactstrap";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";
import Header from "../../components/Headers/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import userimg from "../../assets/img/icons/userDefault.png";

const SubadminForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const { row } = location.state || {};
  const navigate = useNavigate();
  const { accessToken, logout } = useContext(UserContext);
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    profile_pic: null,
    username: "",
    password: "",
    role_id: "",
  });
  const [categories, setCategories] = useState([]);
  const [imagePreview, setImagePreview] = useState(row?.profile_pic || null);
  const FileInputRef = useRef(null);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;

  const headers = {
    "content-type": "multipart/form-data",
    "auth-token": authToken,
    authorization: accessToken,
  };

  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchRole();
    if (id) {
      setFormData({
        fullname: row?.fullname,
        email: row?.email,
        mobile: row?.mobile,
        profile_pic: row?.profile_pic,
        username: row?.username,
        // password: row?.password,
        role_id: row?.role_id,
      });
    }
  }, [id, row]);

  const validate = () => {
    const newErrors = {};

    if (!formData.fullname) {
      newErrors.fullname = "Full name is required.";
    }

    if (!formData.email) {
      newErrors.email = "Email address is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid.";
    }

    if (!formData.mobile) {
      newErrors.mobile = "Mobile number is required.";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = "Mobile number is invalid.";
    }

    if(!id){
    if (!formData.password) {
      newErrors.password = "Password is required.";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters.";
    }
  }

    if (!formData.role_id) {
      newErrors.role_id = "Role is required.";
    } else if (
      !categories.some((category) => category.id == formData.role_id)
    ) {
      newErrors.role_id = "Invalid role selected.";
    }

    if (formData.profile_pic && formData.profile_pic instanceof File) {
      if (
        !["image/jpeg", "image/png", "image/gif"].includes(
          formData.profile_pic.type
        )
      ) {
        newErrors.profile_pic = "Profile picture must be a JPG, PNG, or GIF";
      }
    } else if (!formData.profile_pic && !imagePreview) {
      newErrors.profile_pic = "Profile picture is required.";
    }

    if (!formData.username) {
      newErrors.username = "Username is required.";
    }

    return newErrors;
  };

  const onImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        profile_pic: file,
      }));

      // Create a preview URL for the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // Handle the case where no file is selected
      setImagePreview(null);
      setFormData((prevState) => ({
        ...prevState,
        profile_pic: null,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "profile_pic") {
      setFormData({ ...formData, [name]: files[0] });
    } 
    else if (name === 'mobile' ) {
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Exit the function early if there are validation errors
    }

    setErrors({});

    const formDataToSubmit = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSubmit.append(key, formData[key]);
    });

    try {
      if (id) {
        const formData1 = { ...formData };
        formData1.id = id;
        const res = await APIServices.post(
          `/admin/auth/update_admin`,
          formData1,
          { "content-type": "multipart/form-data", ...headers }
        );
        if (res?.data?.status === true) {
          showToast(res?.data?.message || "Success", "success");
        } else if(res?.status === 401){
          logout();
          navigate("/");
         }else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
      } else {
        const res = await APIServices.post(
          "/admin/auth/add_admin",
          formData,
          headers
        );
        if (res?.data?.status === true) {
          showToast(res?.data?.message || "Success", "success");
        } else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
      }
      setTimeout(() => {
        navigate("/admin/subadmin");
      }, 2000);
    } catch (error) {
      console.error("API call error:", error);
      showToast("An unexpected error occurred. Please try again.", "error");
    }
  };

  const fetchRole = async () => {
    try {
      const response = await APIServices.get(
        `/admin/auth/role_list?limit=100`,
        headers
      );
      const data = await response?.data?.list;
      setCategories(data);
      if (response?.status === 200) {
        const data = response?.data?.list || [];
        setCategories(data);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <>
      <Header />
      <Container className="form-upper" fluid>
        <Form className="mt-5 card_border shadow">
          <div className="form-header1">
            <h1 className="card-title">{id ? "Edit" : "Add"} Subadmin</h1>
          </div>
          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Fullname
            </Label>
            <Input
              type="text"
              name="fullname"
              placeholder="Fullname"
              value={formData?.fullname}
              onChange={handleChange}
            />
            {errors.fullname && (
              <div className="text-danger">{errors.fullname}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Username
            </Label>
            <Input
              type="text"
              name="username"
              placeholder="Username"
              value={formData.username}
              onChange={handleChange}
            />
            {errors.username && (
              <div className="text-danger">{errors.username}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Email
            </Label>
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <div className="text-danger">{errors.email}</div>}
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Mobile
            </Label>
            <Input
              type="text"
              name="mobile"
              placeholder="Mobile"
              maxLength="10"
              value={formData.mobile}
              onChange={handleChange}
            />
            {errors.mobile && (
              <div className="text-danger">{errors.mobile}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Role
            </Label>
            {/* <Input
              type="text"
              name="role_id"
              placeholder="Role Id"
              value={formData.role_id}
              onChange={handleChange}
            /> */}
            <Input
              // className="select-input"
              type="select"
              name="role_id"
              value={formData.role_id}
              onChange={handleChange}
            >
              <option value="">Select Role</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Input>
            {errors.role_id && (
              <div className="text-danger">{errors.role_id}</div>
            )}
          </FormGroup>
          {!id?<FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Password
            </Label>
            <Input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && (
              <div className="text-danger">{errors.password}</div>
            )}
          </FormGroup>:""}
          
          <FormGroup className={errors.profile_pic ? "profile-error" : ""}>
            <Label className="form-control-label required">
              Profile Picture
            </Label>

            <label id="labimg" className="profile-img">
              <img
                className="imgs cursor"
                src={imagePreview || userimg} // replace with your default image path
                width="50%"
                alt=""
              />
              <Input
                className="form-control-alternative "
                id="input-profile-pic"
                name="profile_pic"
                type="file"
                accept="image/jpeg,image/png,image/gif"
                onChange={(e) => {
                  onImageChange(e);
                  handleChange(e);
                }}
                ref={FileInputRef}
                invalid={!!errors.profile_pic}
              />
            </label>

            {errors.profile_pic && (
              <div className="text-danger">{errors.profile_pic}</div>
            )}
          </FormGroup>
          {/* <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Status
            </Label>
            <Input
              type="select"
              name="status"
              value={newrole.status}
              onChange={handleInputChange}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Input>
            {errors.status && (
              <div className="text-danger">{errors.status}</div>
            )}
          </FormGroup> */}
          <Button color="primary" onClick={handleSubmit}>
            {id ? "Save" : "Submit"}
          </Button>
          <Button color="light" onClick={() => navigate("/admin/subadmin")}>
            Back
          </Button>
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
};

export default SubadminForm;
