import React, { useEffect, useState } from "react";
import { getCookies, setCookies } from "../utils/Helper";
import APIServicenew from "../utils/APIGeneralService";

const UserContext = React.createContext();

const UserContextProvider = (props) => {
  const [accessToken, setAccessToken] = useState(getCookies("accessToken"));
  const [data, setData] = useState();
 
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const [permissionscontext, setPermissionscontext] = useState([]);

  const headers = { "auth-token": authToken, authorization: accessToken };

  const logout = (e) => {
    setAccessToken(null);
    setCookies("accessToken", null);
    setCookies("user_type", null);
    setCookies("role_id", null);
    setCookies("name", null);
    
    // localStorage.removeItem('accessToken');
  };

  const fetchData = async () => {
    if (accessToken) {
      try {
        const res = await APIServices.get("admin/auth/profile", headers);
        if (res?.data?.status === true) {
          setData(res?.data?.detail);
        } else {
          console.error(res?.data?.message);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [accessToken]);

  return (
    <UserContext.Provider
      value={{ accessToken, setAccessToken, logout, data, fetchData,setPermissionscontext,permissionscontext }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContextProvider, UserContext };
