import Cookies from "universal-cookie";
import moment from 'moment';

const cookies = new Cookies();

export const setCookies = (name, value, expiryAfterMinutes = 86400) => {
  let expire = new Date();
  expire.setDate(expire.getDate() + expiryAfterMinutes);
  cookies.set(name, value, { expires: expire, path: "/" });
};

export const getCookies = (name) => {
  return cookies.get(name);
};

export const removeCookies = (name, options = { path: "/" }) => {
  cookies.remove(name, options);
};

export function capitalizeFirstLetter(str) {
  if (str) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  } else {
    return str;
  }
}


export const formateDate = date => {
  return moment(date).format('YYYY[-]MM[-]DD');
};

export const formateDatemdy = date => {
  return moment(date).format('MM[/]DD[/]YYYY');
};

export const formateDateandtime = date => {
  return moment(date).format('MM[/]DD[/]YYYY HH:mm');
};






