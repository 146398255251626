import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Form, FormGroup, Input, Container, Label } from "reactstrap";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";
import Header from "../../components/Headers/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DonorForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const { row } = location.state || {};
  const navigate = useNavigate();
  const [newdonor, setDonor] = useState({ title: "", status: "Active" });
  const [errors, setErrors] = useState({});
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };

  useEffect(() => {
    if (id) {
      setDonor({ title: row?.title, status: row?.status });
    }
  }, [id, row]);

  const validate = () => {
    const errors = {};
    if (!newdonor.title) errors.title = "Tilte is required.";
    if (!newdonor.status) errors.status = "Status is required.";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDonor({ ...newdonor, [name]: value });
    if (value) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    try {
      if (id) {
        const payload = { ...newdonor };
        payload.id = id;
        const res = await APIServices.post(
          `/admin/donor/category_update`,
          payload,
          headers
        );
        if (res?.data?.status === true) {
          showToast(res?.data?.message || "Success", "success");
        }else if(res?.status === 401){
          logout()
          navigate("/");
         } else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
      } else {
        const res = await APIServices.post(
          "/admin/donor/category_add",
          newdonor,
          headers
        );
        if (res?.data?.status === true) {
          showToast(res?.data?.message || "Success", "success");
        } else if(res?.status === 401){
          logout();
          navigate("/");
         }else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
      }
      setTimeout(() => {
        navigate("/admin/donor-categories");
      }, 2000);
    } catch (error) {
      console.error("Error submitting FAQ:", error);
      showToast("Something went wrong.", "error");
    }
  };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <>
      <Header />
      <Container className="form-upper" fluid>
        <Form className="mt-5 card_border shadow">
          <div className="form-header1">
            <h1 className="card-title">{id ? "Edit" : "Add"} Donor</h1>
          </div>

          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Title
            </Label>
            <Input
              type="text"
              name="title"
              placeholder="Title"
              value={newdonor.title}
              onChange={handleInputChange}
            />
            {errors.title && <div className="text-danger">{errors.title}</div>}
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Status
            </Label>
            <Input
              type="select"
              name="status"
              value={newdonor.status}
              onChange={handleInputChange}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Input>
            {errors.status && (
              <div className="text-danger">{errors.status}</div>
            )}
          </FormGroup>
          <Button color="primary" onClick={handleSubmit}>
          {id ? "Save" : "Submit"}
          </Button>
          <Button
            color="light"
            onClick={() => navigate("/admin/donor-categories")}
          >
            Back
          </Button>
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
};

export default DonorForm;
