import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import Breadcrumb from "./Breadcrumd";
import { Link, useLocation } from "react-router-dom";

const Header = (props) => {
  const show = props?.dashdata;
  const count = props?.information?.counts;

  const location = useLocation();





  const generateBreadcrumbs = () => {
    const paths = location.pathname.split("/").filter((x) => x);
  
    const breadcrumbs = paths
      .map((path, index) => {
        // Check if this is the last path segment
        const isLast = index === paths.length - 1;
  
        // Detect if the segment is a dynamic ID (e.g., numeric or a specific pattern)
        const isDynamic = /^\d+$/.test(path) || path.startsWith(":");
  
        // Skip the last segment if it's dynamic
        if (isLast && isDynamic) {
          return null;
        }
  
        // Build the path for the breadcrumb
        const routeTo = `/${paths.slice(0, index + 1).join("/")}`;
  
        // Process the path to handle hyphenated words
        const label = path
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
  
        return {
          label,
          path: routeTo,
        };
      })
      .filter(Boolean); // Remove null values
  
    return breadcrumbs;
  };
  

  return (
    <>
      <div className="header  bg-gradient-info bg-subHead pb-5 pt-5 pt-md-6">
        <Container fluid>
          {/* <nav aria-label="breadcrumb" className="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item font_16"><a href="#" className="breadcrumb-link">Home</a></li>
                <li class="breadcrumb-item font_16"><a href="#" className="breadcrumb-link">Master</a></li>
                <li class="breadcrumb-item font_16 active" aria-current="page">Charity</li>
              </ol>
            </nav> */}
          <Breadcrumb items={generateBreadcrumbs()} />
        </Container>
        <Container fluid className="">
          <div className="header-body pb-3 mb-5 mt-3">
            {show && show === true ? (
              <>
                <Row>
                  <Col lg="6" xl="3">
                    <Card className="card-stats card_border mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                          <Link to="/admin/charities">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase  mb-0"
                            >
                              Total Charity
                            </CardTitle>
                            <span className="font_20 clr-navy font-weight-bold mb-0">
                              {count?.[0]?.total_charities}
                            </span>
                            </Link>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats card_border mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                          <Link to="/admin/donors">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase  mb-0"
                            >
                              Total Donor
                            </CardTitle>
                            <span className="font_20 clr-navy font-weight-bold mb-0">
                              {count?.[0]?.total_donors}
                            </span>
                            </Link>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats card_border mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                          <Link to="/admin/contact-us">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase  mb-0"
                            >
                              Total Inqueries
                            </CardTitle>
                            <span className="font_20 clr-navy font-weight-bold mb-0">
                              {count?.[0]?.total_inqueries}
                            </span>
                            </Link>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats card_border mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <Link to="/admin/subscriptions">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase  mb-0"
                            >
                              Total Subscriber
                            </CardTitle>
                            <span className="font_20 clr-navy font-weight-bold mb-0">
                              {count?.[0]?.total_subscriber}
                            </span>
                            </Link>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
