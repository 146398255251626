import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from 'reactstrap';
import APIServicenew from '../../utils/APIGeneralService';
import { UserContext } from '../../context/UserContextProvider';
import Header from '../../components/Headers/Header';
import userimg from "../../assets/img/icons/userDefault.png"
import { formateDatemdy } from '../../utils/Helper';

const DonorDetail = () => {
  const { id } = useParams();
  const navigate =useNavigate();
  const [donorData, setDonorData] = useState(null);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };

  const fetchData = async (id) => {
    try {
      const response = await APIServices.get(
        `/admin/donor/detail/${id}`,
        headers
      );
      if (response?.status === 200) {
        const data1 = response?.data?.detail || [];
        setDonorData(data1);
      } else if(response?.status === 401){
        logout();
        navigate("/");
       }else {
        setDonorData();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDonorData();
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  if (!donorData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5 justify-content-center">
          <Col className="mb-5 mb-xl-0" lg="6">
            <Card className="shadow">
              <CardBody className='details-page'>
                <div className="">
                  <img
                    src={donorData.profile_pic ? donorData.profile_pic:userimg}
                    alt="Profile"
                    className="img-thumbnail detail-img"
                  />
                  </div>
                  <ul className='mt-3 registeration-detail pl-0'>
                        <h1 className='detail-page-heading'>{`${donorData.first_name} ${donorData.last_name}`}</h1>
                        
                        <li className='clr-navy font_500 detail-points'><span className='label-title'>Phone:</span> <span className='detail-text'>{donorData.phone_number}</span></li>
                        <li className='clr-navy font_500 detail-points'><span className='label-title'>Email:</span> <span className='detail-text'>{donorData.email}</span></li>  

                        <li className='clr-navy font_500 detail-points'><span className='label-title'>Category:</span> <span className='detail-text'>{donorData.category_name}</span></li>
                        <li className='clr-navy font_500 detail-points'>
                        <span className='label-title'>Created:</span> <span className='detail-text'>{formateDatemdy(donorData.created)}</span></li>
                        <li className='clr-navy font_500 detail-points'><span className='label-title'>Modified:</span> <span className='detail-text'>{formateDatemdy(donorData.modified??donorData.created)}</span></li>
                       
                    </ul>
              
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DonorDetail;
