import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  FormFeedback,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";
import { useContext, useEffect, useRef, useState } from "react";
import ProfileHeader from "../components/Headers/ProfileHeader";
import CustomAlert from "../components/Alert/CustomAlert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIServicenew from "../utils/APIGeneralService";
import { UserContext } from "../context/UserContextProvider";
import userimg from "../assets/img/icons/userDefault.png";
import { useNavigate } from "react-router-dom";

const Profile = (props) => {
  const navigate =useNavigate()
  const { accessToken, data, fetchData,logout } = useContext(UserContext);
  const [alertOpen, setAlertOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const toggleAlert = () => {
    setAlertOpen(!alertOpen);
  };
  const [formData, setFormData] = useState({
    fullname: data?.fullname || "",
    email: data?.email || "",
    mobile: data?.mobile || "",
    profile_pic: data?.profile_pic || null,
    username: data?.username || "",
  });

  const [imagePreview, setImagePreview] = useState(data?.profile_pic || null);
  const FileInputRef = useRef(null);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;

  const headers = {
    "content-type": "multipart/form-data",
    "auth-token": authToken,
    authorization: accessToken,
  };

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};

    if (!formData.fullname) {
      newErrors.fullname = "Full name is required.";
    }

    if (!formData.email) {
      newErrors.email = "Email address is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid.";
    }

    if (!formData.mobile) {
      newErrors.mobile = "Mobile number is required.";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = "Mobile number is invalid.";
    }

    if (formData.profile_pic && formData.profile_pic instanceof File) {
      if (
        !["image/jpeg", "image/png", "image/gif"].includes(
          formData.profile_pic.type
        )
      ) {
        newErrors.profile_pic = "Profile picture must be a JPG, PNG, or GIF";
      }
    } else if (!formData.profile_pic && !imagePreview) {
      newErrors.profile_pic = "Profile picture is required.";
    }

    if (!formData.username) {
      newErrors.username = "Username is required.";
    }

    return newErrors;
  };

  const onImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        profile_pic: file,
      }));

      // Create a preview URL for the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // Handle the case where no file is selected
      setImagePreview(null);
      setFormData((prevState) => ({
        ...prevState,
        profile_pic: null,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "profile_pic") {
      setFormData({ ...formData, [name]: files[0] });
    } else if (name === 'mobile' ) {
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);

    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoad(false);
      return; // Exit the function early if there are validation errors
    }

    setErrors({});

    const formDataToSubmit = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSubmit.append(key, formData[key]);
    });

    try {
      const res = await APIServices.post(
        "admin/auth/update_profile",
        formDataToSubmit,
        { "content-type": "multipart/form-data", ...headers }
      );

      if (res?.data?.status === true) {
        // setFormData({
        //   fullname: "",
        //   email: "",
        //   mobile: "",
        //   profile_pic: null,
        //   username: "",
        // });
        // if (FileInputRef.current) {
        //   FileInputRef.current.value = "";
        // }
        // setImagePreview("");
        fetchData();
        showToast(res?.data?.message || "Success", "success");
      }else if(res?.status === 401){
        logout();
        navigate("/");
       } else {
        showToast(res?.data?.message || "Something went wrong.", "error");
      }
    } catch (error) {
      console.error("API call error:", error);
      showToast("An unexpected error occurred. Please try again.", "error");
    } finally {
      setLoad(false); // Always reset loading state after the request
    }
  };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 5000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };


  useEffect(() => {
    setFormData({
      fullname: data?.fullname || "",
      email: data?.email || "",
      mobile: data?.mobile || "",
      profile_pic: data?.profile_pic || null,
      username: data?.username || "",
    });
    setImagePreview(data?.profile_pic || null);
  }, [data]);

  return (
    <>
      <ProfileHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      className="clr-navy"
                    >
                      <img
                        alt="image"
                        className="rounded-circle"
                        src={data?.profile_pic || userimg}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4 card_border"></CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5"></div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3 className="card-title">
                    {data?.fullname || data?.username}
                    <br />
                    {data?.email}
                    <br />
                    {data?.mobile}
                    <span className="font-weight-light"></span>
                  </h3>
                  {/* <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Bucharest, Romania
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Solution Manager - Creative Tim Officer
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    University of Computer Science
                  </div> */}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className=" shadow">
              {/* <CardHeader className="bg-white border-0">
        
              </CardHeader> */}
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label required"
                          htmlFor="input-fullname"
                        >
                          Full Name
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-fullname"
                          name="fullname"
                          placeholder="Full Name"
                          type="text"
                          value={formData.fullname}
                          onChange={handleChange}
                          invalid={!!errors.fullname}
                        />
                        {errors.fullname && (
                          <FormFeedback>{errors.fullname}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label required"
                          htmlFor="input-username"
                        >
                          Username
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-username"
                          name="username"
                          placeholder="Username"
                          type="text"
                          value={formData.username}
                          onChange={handleChange}
                          invalid={!!errors.username}
                        />
                        {errors.username && (
                          <FormFeedback>{errors.username}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label required"
                          htmlFor="input-email"
                        >
                          Email Address
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-email"
                          name="email"
                          placeholder="Email"
                          type="email"
                          value={formData.email}
                          onChange={handleChange}
                          invalid={!!errors.email}
                        />
                        {errors.email && (
                          <FormFeedback>{errors.email}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label required"
                          htmlFor="input-phone"
                        >
                          Phone Number
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-phone"
                          name="mobile"
                          placeholder="Mobile"
                          type="text"
                          value={formData.mobile}
                          onChange={handleChange}
                          maxLength={10}
                          invalid={!!errors.mobile}
                        />
                        {errors.mobile && (
                          <FormFeedback>{errors.mobile}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col lg="6">
                      <FormGroup className={errors.profile_pic?"profile-error":""}>
                        <label
                          className="form-control-label "
                          htmlFor="input-profile-pic"
                        >
                          Profile Picture
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-profile-pic"
                          name="profile_pic"
                          type="file"
                          accept="image/jpeg,image/png,image/gif"
                          onChange={handleChange}
                          ref={FileInputRef} 
                          invalid={!!errors.profile_pic}
                        />
                        {errors.profile_pic && (
                          <FormFeedback>{errors.profile_pic}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col> */}
                    <Col lg="6">
                      <FormGroup
                        className={errors.profile_pic ? "profile-error" : ""}
                      >
                        <Label className="form-control-label required">
                          Profile Picture
                        </Label>

                        <label id="labimg" className="profile-img">
                          <img
                            className="imgs cursor"
                            src={imagePreview || userimg} // replace with your default image path
                            width="100%"
                            alt=""
                          />
                          <Input
                            className="form-control-alternative "
                            id="input-profile-pic"
                            name="profile_pic"
                            type="file"
                            accept="image/jpeg,image/png,image/gif"
                            onChange={(e) => {
                              onImageChange(e);
                              handleChange(e);
                            }}
                            ref={FileInputRef}
                            invalid={!!errors.profile_pic}
                          />
                        </label>

                        {errors.profile_pic && (
                          <FormFeedback>{errors.profile_pic}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="primary" type="submit" disabled={load}>
                    {load ? "Updating..." : "Update Profile"}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <CustomAlert
        message=" Successfully Updated!"
        type="success"
        isOpen={alertOpen}
        toggle={toggleAlert}
      />
      <ToastContainer />
    </>
  );
};

export default Profile;
