import { useContext } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { UserContext } from "../../context/UserContextProvider";

const ProfileHeader = (props) => {
  const { data } = useContext(UserContext);

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "100px",
          // backgroundImage:
          //   "url(" + require("../../assets/img/theme/profile-cover.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default bg-changes-n opacity-8" />

        <Container className="" fluid>
          <Row>
            <Col lg="7" md="10">
              {/* <h1 className="display-2 text-white">
                {data?.fullname || data?.username}
              </h1> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProfileHeader;
