import { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Label,
  FormFeedback,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";
import MyEditor from "../../components/Editor/ckeditior";

const PrivacyFrom = (props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    content: "",
  });
  const [errors, setErrors] = useState({});
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };

  const validate = () => {
    const newErrors = {};
    if (!formData.title || formData.title === "") {
      newErrors.title = "Title is required";
    }
    if (!formData.content || formData.content === "") {
      newErrors.content = "Content is required";
    }
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const payload = {
        title: formData.title,
        content: formData.content,
      };
      try {
        const response = await APIServices.post(
          `/admin/cms/privacy_policy`,
          payload,
          headers
        );
        if (response?.data?.status === true) {
          // setFormData({
          //   title: "",
          //   content: "",
          // });
          showToast(response?.data?.message || "Success", "success");
          fetchData();
          setTimeout(() => {
            navigate("/admin/pages");
          }, 2000);
        } else {
          showToast(
            response?.data?.message || "Something went wrong.",
            "error"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };


  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData(prevFormData => ({
        ...prevFormData,
        content: data
    }));
    setErrors({ ...errors, content: "" });
};

  const fetchData = async () => {
    try {
      const response = await APIServices.get(
        `/admin/cms/privacy_policy`,
        headers
      );
      if (response?.status === 200) {
        const formData = response?.data?.detail || [];
        setFormData(formData);
      }else if(response?.status === 401){
        logout();
        navigate("/");
       } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };
  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0 card-title">Edit Privacy Policy</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <Label
                          className="form-control-label required"
                          htmlFor="input-title"
                        >
                          Title
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="input-title"
                          name="title"
                          placeholder="Title"
                          type="text"
                          value={formData?.title}
                          onChange={handleChange}
                          invalid={!!errors.title}
                        />
                        {errors.title && (
                          <FormFeedback>{errors.title}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <Label className="form-control-label required">
                          Content
                        </Label>
                        <MyEditor 
                        content={formData.content}
                        onChange={handleEditorChange}/>
                        <span className="errorVal">{errors?.content}</span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="primary" type="submit">
                  Save
                  </Button>
                  <Button
                    className="btn-theme2"
                    // color="light"
                    onClick={() => navigate("/admin/pages")}
                  >
                    Back
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default PrivacyFrom;
