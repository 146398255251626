import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const MyEditor = ({content,
    
    onChange,
    }) => {


  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={content}
        onChange={(event, editor) => onChange(event, editor)}
      />
    </div>
  );
};

export default MyEditor;
