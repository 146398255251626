import React, { useState } from 'react';
import { Alert, Button } from 'reactstrap';

const CustomAlert = ({ message, type, icon, isOpen, toggle }) => {
    return (
      <Alert color={type} isOpen={isOpen} toggle={toggle} className="custom-alert">
       
        <span className="alert-inner--text">
          <strong></strong> {message}
        </span>
        <button type="button" className="close" onClick={toggle} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </Alert>
    );
  };
export default CustomAlert;