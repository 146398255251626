import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Container,
} from 'reactstrap';
import APIServicenew from '../../utils/APIGeneralService';
import { UserContext } from '../../context/UserContextProvider';
import Header from '../../components/Headers/Header';
import { capitalizeFirstLetter, formateDateandtime } from '../../utils/Helper';
import userimg from "../../assets/img/icons/userDefault.png"

const CharityDetail = () => {
  const { id } = useParams();
  const navigate =useNavigate();
  const [charityData, setCharityData] = useState(null);
  const [activeTab, setActiveTab] = useState('1');
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };

  const fetchData = async (id) => {
    try {
      const response = await APIServices.get(
        `/admin/charity/detail/${id}`,
        headers
      );
      if (response?.status === 200) {
        const data1 = response?.data?.detail || [];
        setCharityData(data1);
      }else if(response?.status === 401){
        logout();
        navigate("/");
       } else {
        setCharityData();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setCharityData();
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  if (!charityData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" md="4">
            <Card className="shadow card_border">
            <CardHeader className="card_border">
                <div className='details-page charity-detail'>
                    <div className='text-center'>
                      <img
                        src={charityData?.profile_pic ? charityData?.profile_pic:userimg}
                        alt="Profile"
                        className="img-thumbnail detail-img"
                        
                      />
                     </div>
                    <ul className='mt-3 registeration-detail pl-0'>
                        <h1 className='detail-page-heading'>{charityData.name}</h1>
                        
                        <li className='clr-navy font_500 detail-points'><span className='label-title'>Registration Number:</span> <span className='detail-text'>{charityData.reg_number}</span></li>
                        <li className='clr-navy font_500 detail-points'><span className='label-title'>Created:</span> <span className='detail-text'>{formateDateandtime(charityData.created)}</span></li>  
                        <li className='clr-navy font_500 detail-points'><span className='label-title'>Phone:</span> <span className='detail-text'>{charityData.phone_number}</span></li>
                        <li className='clr-navy font_500 detail-points'>
                        <span className='label-title'>Email:</span> <span className='detail-text'>{charityData.email}</span></li>
                        <li className='clr-navy font_500 detail-points'><span className='label-title'>Category:</span> <span className='detail-text'>{charityData.category_name}</span></li>
                        <li className='clr-navy font_500 detail-points'><span className='label-title'>Description:</span> <span className='detail-text'>{charityData?.other_info?.description}</span></li>  
                    </ul>
                  </div>
               
            </CardHeader>
      </Card>
          </Col>
          <Col className="mb-5 mb-xl-0" md="8">
            <Card className="shadow tabs-card">
              <CardHeader className="card_border">
              
                <div className='details-page charity-detail'>
                  <Nav tabs className='detail-tabs'>
                  <NavItem>
                      <NavLink
                        className={{ active: activeTab === '1' }}
                        onClick={() => { toggleTab('1'); }}
                      >
                       Other Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={{ active: activeTab === '2' }}
                        onClick={() => { toggleTab('2'); }}
                      >
                        Campaigns
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={{ active: activeTab === '3' }}
                        onClick={() => { toggleTab('3'); }}
                      >
                        Documents
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={{ active: activeTab === '4' }}
                        onClick={() => { toggleTab('4'); }}
                      >
                        Answers
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab} className='detail-tab-content'>
                  <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                        <Card className="address-tab">
                            <CardBody>    
                            <p className='clr-navy  font_500 detail-points'>Address 1: {charityData?.other_info?.address1}</p>
                            <p className='clr-navy  font_500 detail-points'>Address 2: {charityData?.other_info?.address2}</p>
                            <p className='clr-navy  font_500 detail-points'>City: {charityData?.other_info?.city_name}</p>
                            <p className='clr-navy  font_500 detail-points'>Country: {charityData?.other_info?.country_name}</p>
                            <p className='clr-navy  font_500 detail-points'>Postal Code: {charityData?.other_info?.postal_code}</p>
                            <p className='clr-navy  font_500 detail-points'>Social Profile: {charityData?.other_info?.social_profile}</p>
                            <p className='clr-navy  font_500 detail-points'>Website Link: {charityData?.other_info?.website_link}</p>
                            <p className='clr-navy  font_500 detail-points'>Commission Link: {charityData?.other_info?.commission_link}</p> 
                          </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <Campaigns campaigns={charityData.campaigns} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <Documents documents={charityData.documents} />
                        </Col>
                      </Row>
                      </TabPane>
                      <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <Answers answers={charityData.answers} />
                        </Col>
                      </Row>
                      </TabPane>
                    </TabContent>
                   </div>
               </CardHeader>
            </Card>
          </Col>
      </Row>
      </Container>
    </>
  );
};

const Campaigns = ({ campaigns }) => (
  <div >
    {/* campaigns.length > 0 || */}
    {campaigns && campaigns.length > 0 ? (
      campaigns.map((campaign) => (
        <Card key={campaign.id} className="">
          <CardBody>
            <CardTitle tag="h5" className='' >{campaign.title}</CardTitle>
            <CardSubtitle className="mb-2 font_600 mt-0">Category: {campaign.category_name}</CardSubtitle>
            <CardText>
              Status: {campaign.status}
              <br />
              Start Date: {new Date(campaign.start_date).toLocaleDateString()}
              <br />
              End Date: {new Date(campaign.end_date).toLocaleDateString()}
              <br />
              {campaign.description}
            </CardText>
            {campaign.images && campaign.images.length > 0 && (
              <div style={{ marginTop: '10px' }}>
                {campaign.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Campaign ${campaign.title} Image ${index + 1}`}
                    style={{ width: '100%', marginBottom: '10px' }}
                  />
                ))}
              </div>
            )}
          </CardBody>
        </Card>
      ))
    ) : (
      <Card className="">
        <CardBody>
          <CardText className='clr-navy'>No campaigns found.</CardText>
        </CardBody>
      </Card>
    )}
  </div>
);

const Documents = ({ documents }) => (
  <div>
    {/* documents.length > 0 || */}
    { documents && documents.length > 0? (
      documents.map((document) => (
        <Card key={document.id} className="">
          <CardBody>
            <CardTitle tag="h5" className='' >Document </CardTitle>
            <CardText>
              <a href={document.document} target="_blank" rel="noopener noreferrer">
                Download Document
              </a>
              <br />
              Created: {new Date(document.created).toLocaleDateString()}
              <br />
              Status: {document.status}
            </CardText>
          </CardBody>
        </Card>
      ))
    ) : (
      <Card className="">
        <CardBody>
          <CardText className='clr-navy'>No documents found.</CardText>
        </CardBody>
      </Card>
    )}
  </div>
);




const Answers = ({ answers }) => (
  <div>
    {answers && answers.length > 0 ? (
      <table className="align-items-center table-flush table " style={{overflow:"auto",display:"block"}}>
        <thead className='thead-light'>
          <tr>
            <th >Category</th>
            <th>Question</th>
            <th>Answer</th>
            <th>Governance Score</th>
            <th>Overall Score</th>
            <th>Get Score</th>
            <th>Created Date</th>
          </tr>
        </thead>
        <tbody>
          {answers.map((answer) => (
            <tr key={answer.id}>
              <td>{capitalizeFirstLetter(answer.category)}</td>
              <td>{answer.question}</td>
              <td>{answer.answer}</td>
              <td>{answer.governance_score}</td>
              <td>{answer.overall_score}</td>
              <td>{answer.get_score}</td>
              <td>{new Date(answer.created).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p className='clr-navy'>No Answers found.</p>
    )}
  </div>
);


export default CharityDetail;
