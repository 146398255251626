import { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Label,
  FormFeedback,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { useNavigate, useParams } from "react-router-dom";  // Import useParams
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";
import MyEditor from "../../components/Editor/ckeditior";

const Edittemplate = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();  // Get the ID from the URL params
  const [formData, setFormData] = useState({
    subject: "",
    content: "",
  });
  const [errors, setErrors] = useState({});
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };

  const validate = () => {
    const newErrors = {};
    if (!formData.subject || formData.subject === "") {
      newErrors.subject = "Subject is required";
    }
    if (!formData.content || formData.content === "") {
      newErrors.content = "Template is required";
    }
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const payload = {
        id:id,
        subject: formData.subject,
        template: formData.content,
      };
      try {
        const response = await APIServices.post(
          `/admin/settings/email_template_update`,  // Use the ID for updating
          payload,
          headers
        );
        if (response?.data?.status === true) {
          setFormData({
            subject: "",
            content: "",
          });
          showToast(response?.data?.message || "Success", "success");
          fetchData();
          setTimeout(() => {
            navigate("/admin/email-templates");
          }, 2000);
        } else {
          showToast(
            response?.data?.message || "Something went wrong.",
            "error"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData(prevFormData => ({
        ...prevFormData,
        content: data
    }));
    setErrors({ ...errors, content: "" });
  };

  const fetchData = async () => {
    try {
      const response = await APIServices.get(`/admin/settings/email_template_list?id=${id}`, headers);  // Fetch data by ID
      if (response?.status === 200) {
        const fetchedData = response?.data?.list[0] || {};
        setFormData({
            subject: fetchedData.subject || "",
            content: fetchedData.template || ""
        });
      } else if(response?.status === 401) {
        logout();
        navigate("/");
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();  // Fetch the data when the component mounts
  }, [id]);  // Include id as a dependency

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0 card-title">Edit Template</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <Label
                          className="form-control-label required"
                          htmlFor="input-title"
                        >
                          Subject
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="input-title"
                          name="subject"
                          placeholder="subject"
                          type="text"
                          value={formData.subject}
                          onChange={handleChange}
                          invalid={!!errors.subject}
                        />
                        {errors.subject && (
                          <FormFeedback>{errors.subject}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <Label className="form-control-label required">
                          Template
                        </Label>
                        <MyEditor 
                          content={formData.content}
                          onChange={handleEditorChange}
                        />
                        <span className="errorVal">{errors?.content}</span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="primary" type="submit">
                    Save
                  </Button>
                  <Button
                    color="light"
                    onClick={() => navigate("/admin/email-templates")}
                  >
                    Back
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default Edittemplate;

