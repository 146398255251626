
import Index from "./pages/index";
// import UserList from "./pages/userList";


var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Index />,
    layout: "/admin",
  },
  // {
  //   path: "/user-list",
  //   name: "User List",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: <UserList />,
  //   layout: "/admin",
  // },
  {
    path: "/profile",
    name: "My Profile",
  },
  {
    path: "/update-password",
    name: "UPDATE PASSWORD",
  },

  {
    path: "/document",
    name: "Document",
  },

  {
    path: "/user-details",
    name: "User Profile",
  },

];
export default routes;
