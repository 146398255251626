import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

const StatusUpdateModal = ({ isOpen, toggle, onSave,setRow,row }) => {
  const [status, setStatus] = useState("");
  const [notes, setNotes] = useState("");
  const [statusError, setStatusError] = useState(false);
  const [notesError, setNotesError] = useState(false);

  const handleSave = () => {
    let hasError = false;

    if (status === "") {
      setStatusError(true);
      hasError = true;
    } else {
      setStatusError(false);
    }

    if (notes.trim() === "") {
      setNotesError(true);
      hasError = true;
    } else {
      setNotesError(false);
    }

    if (!hasError) {
      onSave(status, notes);
      toggle(); 
      Cleardata();
    }
  };

useEffect(()=>{
    setNotes(row?.notes??"")

},[row])


  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    if (e.target.value !== "") {
      setStatusError(false); 
    }
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
    if (e.target.value.trim() !== "") {
      setNotesError(false); 
    }
  };

  const Cleardata = () => {
    setStatus("");
    setNotes("");
    setStatusError(false);
    setNotesError(false);
    setRow("")
  };

  return (
    <Modal isOpen={isOpen} toggle={() => {toggle(); Cleardata();}} centered>
      <ModalHeader  toggle={() => {toggle(); Cleardata();}}>Update Status</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="statusSelect " className="required">Status</Label>
          <Input
            type="select"
            id="statusSelect"
            value={status}
            onChange={handleStatusChange}
            invalid={statusError}
          >
            <option value="" disabled>
              Select Status
            </option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
          </Input>
          {statusError && <FormFeedback>Status is required.</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <Label for="notes " className="required">Notes</Label>
          <Input
            type="textarea"
            id="notes"
            value={notes}
            onChange={handleNotesChange}
            placeholder="Add any notes here..."
            invalid={notesError}
          />
          {notesError && <FormFeedback>Notes cannot be empty.</FormFeedback>}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button color="secondary" onClick={() => { toggle(); Cleardata(); }}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StatusUpdateModal;
