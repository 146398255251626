// ReplyContact.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col
} from 'reactstrap';
import Header from "../../components/Headers/Header";
import APIServicenew from "../../utils/APIGeneralService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from '../../context/UserContextProvider';
import { formateDateandtime} from '../../utils/Helper';

const ReplyContact = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [contact, setContact] = useState({});
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken,logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };


  const validate = () => {
    const errors = {};
   
    if (!message) {
      errors.message = "Message is required.";
    } 
    
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const fetchContactDetails = async () => {
    try {
      const response = await APIServices.get(`/admin/contactus/detail/${id}`, 
        headers,
      );
      if (response.status === 200) {
        setContact(response.data.detail);
      } else if(response?.status === 401){
        logout();
        navigate("/");
       }else {
        toast.error("Failed to fetch contact details");
      }
    } catch (error) {
      console.error("Error fetching contact details:", error);
      toast.error("An error occurred while fetching contact details");
    }
  };

  useEffect(() => {
    fetchContactDetails();
  }, [id]);

  const handleReplySubmit = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
      const payload = { id, message };
      const response = await APIServices.post('/admin/contactus/reply', payload, 
        headers
      );
      if (response?.data?.status === true) {
        showToast(response?.data?.message || "Success", "success");
        fetchContactDetails();
        setMessage("")
      } else {
        toast.error("Failed to send reply");
      }
    }
    } catch (error) {
      console.error("Error sending reply:", error);
      toast.error("An error occurred while sending the reply");
    }
  };


  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };



  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    if (errors.message) {
      setErrors((prevErrors) => ({ ...prevErrors, message: "" }));
    }
  };
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
      <Row className='mt-5'>
        <Col className="order-xl-1" xl="12">
          <Card className="shadow card_border">
            <CardHeader className="bg-white">
              <h3 className="mb-0 card-title">Reply to Contact</h3>
            </CardHeader>
            <CardBody className='pt-0 bg-white'>
              <Form onSubmit={handleReplySubmit}>
                <Row>
                  <Col lg="12">
                    <div className='mb-4'>
                    <div className='d-flex justify-content-between'>
                      <p className='clr-navy font_600 font_14 mb-1'>Name: {contact.name || ''}</p>
                      <p className='clr-navy font_600 font_14 mb-1'> {formateDateandtime(contact.created) || ''}</p>
                      </div>
                    <p className='clr-navy font_600 font_14 mb-1'>Email: {contact.email || ''}</p>
                   
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label">Message</label>
                      <div className="chat-bubble">
                        <p className='clr-navy font_500 font_14'>{contact.message || ''}</p>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                {/* Display all replies */}
                {contact.replies && contact.replies.length > 0 && (
                  <Row>
                    <Col lg="12">
                      <div className="replies-section">
                        {contact.replies.map((reply, index) => (
                          <div key={index} className="reply-bubble">
                           <div className='d-flex justify-content-between'> <strong>{reply.repliername}</strong> <small className='mr-1'>{formateDateandtime(reply.created)}</small></div>
                            <p className='clr-navy font_500 font_14'>{reply.message}</p>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label required">Reply Message</label>
                      <Input
                        type="textarea"
                        value={message}
                        onChange={(e) => handleMessageChange(e)}
                        placeholder="Write your reply here"
                       
                      />
                      {errors.message && (
                  <div className="errorVal mt-2">{errors.message}</div>
                )}
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" type="submit">
                  Send Reply
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    <ToastContainer />
    </>
  );
};

export default ReplyContact;
