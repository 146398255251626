import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardHeader, Container, Row, Col, Table } from "reactstrap";
import Header from "../../components/Headers/Header";
import { UserContext } from "../../context/UserContextProvider";
import CustomAlert from "../../components/Alert/CustomAlert";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { capitalizeFirstLetter } from "../../utils/Helper";
import useMenuPermissions from "../../components/Customhook/custompermission";
import Notauthorized from "../../components/Not-authorized/notauthorized"; 
import APIServicenew from "../../utils/APIGeneralService";

const EmailTemplate = () => {
  const navigate = useNavigate();
  const { accessToken,logout } = useContext(UserContext);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const headers = { "auth-token": authToken, authorization: accessToken };
  const permissionscontext = useMenuPermissions("email-templates");

  const [data1, setData1] = useState([]);

  const [alertOpen, setAlertOpen] = useState(false);




  const fetchData = async () => {
  
    try {
      const response = await APIServices.get(
        `/admin/settings/email_template_list`,
        headers
      );
      if (response?.status === 200) {
        const data1 = response?.data?.list || [];
        setData1(data1);
       
      } else if(response?.status === 401){
        logout();
        navigate("/");
       }else {
        setData1([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData1([]);
    }
  };

  useEffect(()=>{
    fetchData()
  },[])

  return (
    <>
     {permissionscontext.view ? <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0 card-title">Email Templates</h3>
                  </div>
                </Row>
              </CardHeader>
              {data1 && Array.isArray(data1) && data1.length !== 0 ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Title</th>
                      {/* <th scope="col">Subject</th> */}
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data1.map((row, index) => (
                      <tr key={row.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{capitalizeFirstLetter(row.title)}</td>
                        {/* <td>{capitalizeFirstLetter(row.subject)}</td> */}
                        <td>
                          <Button
                            color="light"
                            onClick={() =>navigate(`/admin/email-templates/update/${row.id}`)}
                            size="sm"
                            className="btn-theme1"
                            disabled={!permissionscontext.edit}
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h2 className="text-center mb-4">Not Found..</h2>
              )}
            </Card>
          </Col>
        </Row>
        <CustomAlert
          isOpen={alertOpen}
          toggle={() => setAlertOpen(false)}
          color="success"
          message="Action Successful"
        />
      </Container>
      <ToastContainer />
      </>: (
       <Notauthorized />
      )}
    </>
  );
};

export default EmailTemplate;
