import React, { useState, useEffect, useContext } from "react";
import { NavLink as NavLinkRRD, useNavigate } from "react-router-dom";
import { Collapse, NavItem, NavLink, Navbar, Container, Nav } from "reactstrap";
import { setCookies } from "../../utils/Helper";
import charity from "../../assets/img/icons/charitex.png";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [menuItems, setMenuItems] = useState([]);

  const navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken,setPermissionscontext } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };

  useEffect(() => {
    fetchMenuData();
  }, []);

  const fetchMenuData = async () => {
    try {
      const response = await APIServices.get(
        "/admin/auth/get_side_menu",
        headers
      );
      if (response?.data?.status) {
        setMenuItems(response?.data?.list);
        setPermissionscontext(response?.data?.list)
      } else {
        console.error("Error: ", response?.data.message);
      }
    } catch (error) {
      console.error("Error fetching menu data:", error);
    }
  };

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);

    // Close all dropdowns when a link is clicked
    // setDropdownOpen({});
  };

  const toggleDropdown = (menuItem) => {
    setDropdownOpen((prevState) => {
      const newState = {};

      // Close all dropdowns
      Object.keys(prevState).forEach((key) => {
        newState[key] = false;
      });

      // Toggle the clicked dropdown
      newState[menuItem] = !prevState[menuItem];

      return newState;
    });
  };

  const handleLogout = (e) => {
    e.preventDefault();
    setCookies("accessToken", null);
    setCookies("user_type", null);
    setCookies("role_id", null);
    setCookies("name", null);
    navigate("/");
  };

  const createLinks = (items) => {
    return items.map((item, key) => {
      const hasChildren =
        item.children &&
        Array.isArray(item.children) &&
        item.children.length > 0;
      return (
        <NavItem key={key} className="sidebar-nav-item w-100">
          {hasChildren ? (
            <>
              <div
                className="dropdown-toggle w-100 text-left"
                onClick={() => toggleDropdown(item.name)}
                style={{ cursor: "pointer" }}
              >
                <i className={item.icon} />
                <span className="droptextcs menu-link">{item.name}</span>
              </div>
              <Collapse isOpen={dropdownOpen[item.name]}>
                <div className="sub-menu-item">
                  {item.children.map((subItem, subKey) => (
                    <NavLink
                      key={subKey}
                      to={subItem.url}
                      tag={NavLinkRRD}
                      onClick={closeCollapse}
                      className="sub-menu-link clr-navy"
                    >
                      <i className={subItem.icon} />
                      {subItem.name}
                    </NavLink>
                  ))}
                </div>
              </Collapse>
            </>
          ) : (
            <NavLink className="menu-link" to={item.url} tag={NavLinkRRD} onClick={closeCollapse}>
              <i className={item.icon} />
              {item.name}
            </NavLink>
          )}
        </NavItem>
      );
    });
  };

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <img alt="logo" className="navbar-brand-img " src={charity} />
        <Collapse navbar isOpen={collapseOpen}>
          <Nav navbar>{createLinks(menuItems)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Sidebar;
