import { useContext, useEffect, useState } from "react";
// import classnames from "classnames";

// import Chart from "chart.js";

// import { Line, Bar } from "react-chartjs-2";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Header from "../components/Headers/Header";
import APIServicenew from "../utils/APIGeneralService";
import { UserContext } from "../context/UserContextProvider";
import useMenuPermissions from "../components/Customhook/custompermission"; //custom hook for role bassed permission menu option 
import Notauthorized from "../components/Not-authorized/notauthorized";  // not authorized page 
import { useNavigate } from "react-router-dom";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [data1, setData1] = useState();
  const navigate =useNavigate();
  const permissionscontext = useMenuPermissions("dashboard");

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);

  const headers = { "auth-token": authToken, authorization: accessToken };

  const fetchData = async () => {
    if (accessToken) {
      try {
        const res = await APIServices.get("admin/analytics/dashboard", headers);
        if (res?.data?.status === true) {
          setData1(res?.data);
        }else if(res?.status === 401){
          logout();
          navigate("/");
         } else {
          console.error(res?.data?.message);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [accessToken]);
  return (
    <>
      {permissionscontext.view ? (
        <>
          <Header dashdata={true} information={data1} />
          <Container className="mt--7" fluid>
            <Row></Row>
            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow card_border">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0 clr-navy card-title">
                          {" "}
                          Top Charity Views
                        </h3>
                      </div>
                      <div className="col text-right">
                        {/* <Button
                    color="primary"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    See all
                  </Button> */}
                      </div>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Charity name</th>
                        <th scope="col">View</th>
                        {/* <th scope="col">Unique users</th>
                  <th scope="col">Bounce rate</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data1?.charitiViews.length > 0 ? (
                        data1?.charitiViews.map((item) => (
                          <tr key={item.id}>
                            {" "}
                            {/* Use a unique key for each item */}
                            <th scope="row">{item.name}</th>{" "}
                            {/* Assuming "name" is a property in "item" */}
                            <td>{item.views}</td>{" "}
                            {/* Assuming "value" is a property in "item" */}
                            {/* Uncomment and adjust the following lines if needed */}
                            {/* <td>{item.someOtherProperty}</td> */}
                            {/* <td>
      <i className="fas fa-arrow-down text-danger mr-3" />{" "}
      {item.percentage}%
    </td> */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center">
                            Not Found..
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card>
              </Col>
              {/* <Col xl="4">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Social traffic</h3>
                </div>
                <div className="col text-right">
                  <Button
                    color="primary"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    See all
                  </Button>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Referral</th>
                  <th scope="col">Visitors</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Facebook</th>
                  <td>1,480</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <span className="mr-2">60%</span>
                      <div>
                        <Progress
                          max="100"
                          value="60"
                          barClassName="bg-gradient-danger"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Facebook</th>
                  <td>5,480</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <span className="mr-2">70%</span>
                      <div>
                        <Progress
                          max="100"
                          value="70"
                          barClassName="bg-gradient-success"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Google</th>
                  <td>4,807</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <span className="mr-2">80%</span>
                      <div>
                        <Progress max="100" value="80" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Instagram</th>
                  <td>3,678</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <span className="mr-2">75%</span>
                      <div>
                        <Progress
                          max="100"
                          value="75"
                          barClassName="bg-gradient-info"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">twitter</th>
                  <td>2,645</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <span className="mr-2">30%</span>
                      <div>
                        <Progress
                          max="100"
                          value="30"
                          barClassName="bg-gradient-warning"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col> */}
            </Row>
          </Container>
        </>
      ) : (
       <Notauthorized />
      )}
      {/* chart use dashdata */}
    </>
  );
};

export default Index;
