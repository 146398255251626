import { useContext } from 'react';
import { UserContext } from '../../context/UserContextProvider';

const useMenuPermissions = (urlOrId) => {
    const { permissionscontext } = useContext(UserContext);
   

    // Find the menu item based on URL or ID
    const findMenuItem = (items, urlOrId) => {
        for (let item of items) {
            if (item.url == urlOrId || item.id == urlOrId) {
                return item;
            }
            if (item.children && item.children.length > 0) {
                const found = findMenuItem(item.children, urlOrId);
                if (found) return found;
            }
        }
        return null;
    };

    const menuItem = findMenuItem(permissionscontext, urlOrId);

   

    if (!menuItem) {
        return { view: false, add: false, edit: false, delete: false };
    }

    return {
        view: menuItem.view == 1?true:false,
        add: menuItem.add == 1?true:false,
        edit: menuItem.edit == 1?true:false,
        delete: menuItem.delete == 1?true:false,
    };
};

export default useMenuPermissions;

