import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Container,
  Label,
  Table,
} from "reactstrap";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";
import Header from "../../components/Headers/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RolePermissionFormUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [role, setRole] = useState({
    name: "",
    status: "Active",
    permissions: [],
  });
  const [updaterole, setUpdaterole] = useState({
    name: "",
    status: "Active",
    permissions: [],
  });
  const [menuItems, setMenuItems] = useState([]);
  const [errors, setErrors] = useState({});
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };

  useEffect(() => {
    fetchMenuData();
    if (id) {
      fetchRoleData(id);
    }
  }, [id]);

  const fetchMenuData = async () => {
    try {
      const response = await APIServices.get(
        "/admin/auth/get_perission_sections",
        headers
      );
      if (response?.data?.status) {
        const menuData = response?.data?.list.map((item) => ({
          ...item,
          children: Array.isArray(item.children) ? item.children : [],
        }));
        setMenuItems(menuData);
      } else {
        console.error("Error: ", response?.data.message);
      }
    } catch (error) {
      console.error("Error fetching menu data:", error);
    }
  };

  const fetchRoleData = async (roleId) => {
    try {
      const response = await APIServices.get(
        `/admin/auth/get_role/${roleId}`,
        headers
      );
      if (response?.data?.status) {
        const roleData = response.data.detail || {};
        setRole({
          name: roleData.name || "", // Ensure name is handled if undefined
          status: roleData.status || "Active", // Provide default values
          permissions: roleData.permissions || [],
        });
        setUpdaterole({
          name: roleData.name || "",
          status: roleData.status || "Active",
          permissions: roleData.permissions || [],
        });
      } else if(response?.status === 401){
        logout();
        navigate("/");
       }else {
        console.error("Error: ", response?.data.message);
      }
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!updaterole.name) newErrors.name = "Name is required.";
    if (!updaterole.status) newErrors.status = "Status is required.";
    if (updaterole.permissions.length == 0) {
      newErrors.permissions = "At least one permission must be selected.";
    } else {
      // Check if at least one 'view' permission is true
      const hasViewPermission = updaterole.permissions.some(
        (permission) => permission.view
      );

      if (!hasViewPermission) {
        newErrors.permissions =
          "At least one 'View' permission must be selected.";
      }
    }

    setErrors(newErrors);
    return newErrors;
  };


  useEffect(()=>{
    if(role){
      handlesetpremission(role?.permissions)
    }
  },[role])

  const handlesetpremission = (updatedPermissions) => {
    const permissionMap = updatedPermissions.reduce((map, item) => {
      map[item.section_id] = item;
      return map;
    }, {});

    const mergePermissions = (menu, permissions) => {
      return menu.map((item) => {
        const permission = permissions[item.id];
        return {
          ...item,
          view: permission?.view ?? item.view,
          add: permission?.add ?? item.add,
          edit: permission?.edit ?? item.edit,
          delete: permission?.delete ?? item.delete,
          children: item.children
            ? mergePermissions(item.children, permissions)
            : item.children,
        };
      });
    };

    setUpdaterole({
      ...updaterole,
      permissions: mergePermissions(menuItems, permissionMap),
    });
  };

  const permissions = ["View", "Add", "Edit", "Delete"];

  const handlePermissionChange = (
    menuId,
    permissionType,
    name,
    hasChildren = false,
    e
  ) => {
    setRole((prevRole) => {
      const updatedPermissions = [...prevRole.permissions];
      const permissionKey = permissionType.toLowerCase();
      const isChecked = e.target.checked;

      const existingPermissionIndex = updatedPermissions.findIndex(
        (perm) => perm.section_id == menuId
      );

      if (existingPermissionIndex >= 0) {
        // Update existing permission
        updatedPermissions[existingPermissionIndex][permissionKey] = isChecked;
      } else {
        // Add new permission
        const newPermission = {
          section_id: menuId,
          role_id: prevRole.permissions[0]?.role_id || null,
          name,
          view: permissionType == "View" ? isChecked : false,
          add: permissionType == "Add" ? isChecked : false,
          edit: permissionType == "Edit" ? isChecked : false,
          delete: permissionType == "Delete" ? isChecked : false,
        };
        updatedPermissions.push(newPermission);
      }

      if (hasChildren) {
        // Update children permissions
        const children =
          menuItems.find((item) => item.id == menuId)?.children || [];
        children.forEach((child) => {
          const childIndex = updatedPermissions.findIndex(
            (perm) => perm.section_id == child.id
          );
          if (childIndex >= 0) {
            updatedPermissions[childIndex][permissionKey] = isChecked;
          } else {
            const newChildPermission = {
              section_id: child.id,
              role_id: prevRole.permissions[0]?.role_id || null,
              name: child.name,
              view: permissionType == "View" ? isChecked : false,
              add: permissionType == "Add" ? isChecked : false,
              edit: permissionType == "Edit" ? isChecked : false,
              delete: permissionType == "Delete" ? isChecked : false,
            };
            updatedPermissions.push(newChildPermission);
          }
        });
      }

      handlesetpremission(updatedPermissions);
      return { ...prevRole, permissions: updatedPermissions };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdaterole({ ...updaterole, [name]: value });

    const updatedErrors = { ...errors, [name]: "" };

    if (updaterole.permissions.length > 0) {
      updatedErrors.permissions = "";
    }

    setErrors(updatedErrors);
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        // return
        const payload = { ...updaterole, id };
        const response = await APIServices.post(
          `/admin/auth/update_role`,
          payload,
          headers
        );
        if (response?.data?.status === true) {
          showToast(response?.data?.message || "Success", "success");
          setTimeout(() => {
            navigate("/admin/roles");
          }, 2000);
        } else {
          showToast(
            response?.data?.message || "Something went wrong.",
            "error"
          );
        }
      } catch (error) {
        showToast("Something went wrong.", "error");
      }
    }
  };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <>
      <Header />
      <Container className="form-upper" fluid>
        <Form className="mt-5 card_border shadow">
          <div className="form-header1">
            <h1 className="card-title">{id ? "Edit Role" : "Add Role"}</h1>
          </div>

          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Name
            </Label>
            <Input
              type="text"
              name="name"
              placeholder="Name"
              value={updaterole.name}
              onChange={handleChange}
            />
            {errors.name && <div className="text-danger">{errors.name}</div>}
          </FormGroup>

          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-status"
            >
              Status
            </Label>
            <Input
              type="select"
              name="status"
              value={updaterole.status}
              onChange={handleChange}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Input>
            {errors.status && (
              <div className="text-danger">{errors.status}</div>
            )}
          </FormGroup>

          <h3>Role Permissions</h3>
          {errors.permissions && (
            <div className="text-danger">{errors.permissions}</div>
          )}
          <Table className="role-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Menu</th>
                {permissions.map((perm) => (
                  <th key={perm}>{perm}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {menuItems.map((menuItem, index) => (
                <React.Fragment key={menuItem.id}>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{menuItem.name}</td>
                    {permissions.map((perm) => (
                      <td className='table-checkbox' key={perm} style={{ paddingLeft: "32px" }}>
                        <Input
                          type="checkbox"
                          checked={
                            !!role.permissions.find(
                              (p) => p.section_id == menuItem.id
                            )?.[perm.toLowerCase()]
                          }
                          onChange={(e) =>
                            handlePermissionChange(
                              menuItem.id,
                              perm,
                              menuItem.name,
                              !!menuItem.children.length,
                              e
                            )
                          }
                          disabled={
                            perm !== "View" &&
                            !role.permissions.find(
                              (p) => p.section_id == menuItem.id
                            )?.view
                          }
                        />
                      </td>
                    ))}
                  </tr>
                  {menuItem.children.map((child) => (
                    <tr key={child.id} style={{ paddingLeft: "40px" }}>
                      <td></td>
                      <td>{child.name}</td>
                      {permissions.map((perm) => (
                        <td className='table-checkbox' key={perm} style={{ paddingLeft: "32px" }}>
                          <Input
                            type="checkbox"
                            checked={
                              !!role.permissions.find(
                                (p) => p.section_id == child.id
                              )?.[perm.toLowerCase()]
                            }
                            onChange={(e) =>
                              handlePermissionChange(
                                child.id,
                                perm,
                                child.name,
                                false,
                                e
                              )
                            }
                            disabled={
                              perm !== "View" &&
                              !role.permissions.find(
                                (p) => p.section_id == child.id
                              )?.view
                            }
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>

          <Button color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
};

export default RolePermissionFormUpdate;
