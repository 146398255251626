import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Form, FormGroup, Input, Container, Label } from "reactstrap";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";
import Header from "../../components/Headers/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FaqForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const { row } = location.state || {};
  const navigate = useNavigate();
  const [newFaq, setNewFaq] = useState({
    question: "",
    answer: "",
    sort_order: "",
    status: "Active",
  });
  const [errors, setErrors] = useState({});
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };

  useEffect(() => {
    if (id) {
      setNewFaq({
        question: row?.question,
        answer: row?.answer,
        sort_order: row?.sort_order,
        status: row?.status,
      });
    }
  }, [id, row]);

  const validate = () => {
    const errors = {};
    if (!newFaq.question) errors.question = "Question is required.";
    if (!newFaq.answer) errors.answer = "Answer is required.";
    if (!newFaq.sort_order) {
      errors.sort_order = "Sort order is required.";
    } else if (isNaN(newFaq.sort_order)) {
      errors.sort_order = "Sort order must be a number.";
    }
    if (!newFaq.status) errors.status = "Status is required.";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFaq({ ...newFaq, [name]: value });
    if (value) {
      setErrors({ ...errors, [name]: "" });
    }
    if (name === 'sort_order' ) {
      const numericValue = value.replace(/[^0-9]/g, '');
      setNewFaq({
        ...newFaq,
        [name]: numericValue,
      });
    } 
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    try {
      if (id) {
        const payload = { ...newFaq };
        payload.id = id;
        const res = await APIServices.post(
          `/admin/cms/faq_update`,
          payload,
          headers
        );
        if (res?.data?.status === true) {
          showToast(res?.data?.message || "Success", "success");
        } else if(res?.status === 401){
          logout();
          navigate("/");
         }else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
      } else {
        const res = await APIServices.post(
          "/admin/cms/faq_add",
          newFaq,
          headers
        );
        if (res?.data?.status === true) {
          showToast(res?.data?.message || "Success", "success");
        }else if(res?.status === 401){
          logout();
          navigate("/");
         } else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
      }
      setTimeout(() => {
        navigate("/admin/faq");
      }, 2000);
    } catch (error) {
      console.error("Error submitting FAQ:", error);
      showToast("Something went wrong.", "error");
    }
  };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <>
      <Header />
      <Container className="form-upper" fluid>
        <Form className="mt-5 card_border shadow">
          <div className="form-header1">
            <h1
              className="
            card-title"
            >
             {id ? "Edit" : "Add"} FAQ
            </h1>
          </div>

          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Title
            </Label>
            <Input
              type="text"
              name="question"
              placeholder="Question"
              value={newFaq.question}
              onChange={handleInputChange}
            />
            {errors.question && (
              <div className="text-danger">{errors.question}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Answer
            </Label>
            <Input
              type="text"
              name="answer"
              placeholder="Answer"
              value={newFaq.answer}
              onChange={handleInputChange}
            />
            {errors.answer && (
              <div className="text-danger">{errors.answer}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Sort order
            </Label>
            <Input
              type="text"
              name="sort_order"
              placeholder="Sort order"
              value={newFaq.sort_order}
              onChange={handleInputChange}
            />
            {errors.sort_order && (
              <div className="text-danger">{errors.sort_order}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Status
            </Label>
            <Input
              type="select"
              name="status"
              value={newFaq.status}
              onChange={handleInputChange}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Input>
            {errors.status && (
              <div className="text-danger">{errors.status}</div>
            )}
          </FormGroup>
          <Button color="primary" onClick={handleSubmit}>
          {id ? "Save" : "Submit"}
          </Button>
          <Button color="light" onClick={() => navigate("/admin/faq")}>
            Back
          </Button>
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
};

export default FaqForm;
