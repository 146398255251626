import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  FormFeedback,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";
import CustomAlert from "../../components/Alert/CustomAlert";
import ProfileHeader from "../../components/Headers/ProfileHeader";
import userimg from "../../assets/img/icons/userDefault.png";
import { useNavigate } from "react-router-dom";

const UpdatePassword = (props) => {
  // const data = props?.data;
  const navigate =useNavigate()
  const [alertOpen, setAlertOpen] = useState(false);
  const toggleAlert = () => {
    setAlertOpen(!alertOpen);
  };
  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
  });

  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, data, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };

  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);

  const validate = () => {
    const newErrors = {};

    if (!formData.current_password) {
      newErrors.current_password = "Current password is required.";
    }

    if (!formData.new_password) {
      newErrors.new_password = "New password is required.";
    } else if (formData.new_password.length < 6) {
      newErrors.new_password = "New password must be at least 6 characters.";
    }else if (formData.new_password.length > 20) {
      newErrors.new_password = "New password must not exceed 20 characters.";
    }

    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);

    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoad(false);
      return; // Exit the function early if there are validation errors
    }

    setErrors({});

    try {
      const res = await APIServices.post(
        "admin/auth/change_password",
        formData,
        headers
      );

      if (res?.data?.status === true) {
        setFormData({
          current_password: "",
          new_password: "",
        });
        showToast(res?.data?.message || "Success", "success");
      } else if(res?.status === 401){
        logout();
        navigate("/");
       }else {
        showToast(res?.data?.message || "Something went wrong.", "error");
      }
    } catch (error) {
      console.error("API call error:", error);
      showToast("An unexpected error occurred. Please try again.", "error");
    } finally {
      setLoad(false); // Always reset loading state after the request
    }
  };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <>
      <ProfileHeader data={data} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="image"
                        className="rounded-circle"
                        src={data?.profile_pic || userimg}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4 card_border"></CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5"></div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>
                    {data?.fullname || data?.username}
                    <br />
                    {data?.email}
                    <br />
                    {data?.mobile}
                    <span className="font-weight-light"></span>
                  </h3>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="shadow">
              {/* <CardHeader className="bg-white border-0">
              </CardHeader> */}
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label required"
                          htmlFor="input-current-password"
                        >
                          Current Password
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-current-password"
                          name="current_password"
                          placeholder="Current Password"
                          type="password"
                          autoComplete="current-password"
                          value={formData.current_password}
                          onChange={handleChange}
                          maxLength={20}
                          invalid={!!errors.current_password}
                        />
                        {errors.current_password && (
                          <FormFeedback>{errors.current_password}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label required"
                          htmlFor="input-new-password"
                        >
                          New Password
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-new-password"
                          name="new_password"
                          placeholder="New Password"
                          type="password"
                          autoComplete="new-password"
                          value={formData.new_password}
                          onChange={handleChange}
                          maxLength={20}
                          invalid={!!errors.new_password}
                        />
                        {errors.new_password && (
                          <FormFeedback>{errors.new_password}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="primary" type="submit" disabled={load}>
                    {load ? "Updating..." : "Update Password"}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <CustomAlert
        message=" Successfully Updated!"
        type="success"
        isOpen={alertOpen}
        toggle={toggleAlert}
      />
      <ToastContainer />
    </>
  );
};

export default UpdatePassword;
