import { Link } from "react-router-dom";

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { setCookies } from "../../utils/Helper";
import { useContext, useEffect, useState } from "react";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";
import user from "../../assets/img/icons/userDefault.png";

const AdminNavbar = (props) => {
  const Navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, data, logout } = useContext(UserContext);

  const headers = { "auth-token": authToken, authorization: accessToken };

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
    setCookies("accessToken", null);
    setCookies("user_type", null);
    setCookies("role_id", null);
    setCookies("name", null);
    Navigate("/");
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <div
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            // to={`/admin/${props.titleText}`}
          >
            {props.titleText}
          </div>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img  src={data?.profile_pic || user}  alt="Profile"/>
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold profile-title">
                      {data?.fullname || data?.username}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0 font_14">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/update-password" tag={Link}>
                  <i className="ni ni-lock-circle-open" />
                  <span>Update Password</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={(e) => handleLogout(e)}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
