import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  List,
} from "reactstrap";

import Header from "../components/Headers/Header";
import image from "../assets/img/theme/team-4-800x800.jpg";
import moment from "moment";

const UserDetails = (props) => {
  document.title = "User Profile";

  const { state } = useLocation();
  const data = state?.row;

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <CardBody>
                  <div className="container">
                    <div className="profile_inner_text">
                      <ul className="list-unstyled">
                        <li>
                          <span className="first">Image</span>
                          <span className="last">
                            <img
                              src={image}
                              alt="image"
                              height="100"
                              width="100"
                            />
                          </span>
                        </li>

                        <li className="">
                          <span className="first">Name</span>
                          <span className="last text-capitalize">
                            <span>:</span>
                            {data?.name}
                          </span>
                        </li>
                        <li>
                          <span className="first">Contact Number</span>{" "}
                          <span className="last text-capitalize">
                            {" "}
                            <span>:</span>
                            {data.mobile}
                          </span>
                        </li>
                        <li>
                          <span className="first">Email</span>{" "}
                          <span className="last">
                            {" "}
                            <span>:</span>
                            {data?.email}
                          </span>
                        </li>
                        <li>
                          <span className="first">Gender</span>{" "}
                          <span className="last text-capitalize">
                            {" "}
                            <span>:</span>
                            {data?.gender}
                          </span>
                        </li>
                        <li>
                          <span className="first">Birth Date</span>{" "}
                          <span className="last text-capitalize">
                            {" "}
                            <span>:</span>
                            {data?.date}
                          </span>
                        </li>
                        <li>
                          <span className="first">Birth Place</span>{" "}
                          <span className="last text-capitalize">
                            {" "}
                            <span>:</span>
                            {data?.place}
                          </span>
                        </li>
                        <li>
                          <span className="first">Content</span>{" "}
                          <span className="last text-capitalize">
                            {" "}
                            <span>:</span>
                            {data?.content}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserDetails;
