import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import logo from "../../assets/img/icons/charitex.png";

const AdminNavbar = () => {
  return (
    <>
      <Navbar
        className="navbar-top navbar-horizontal navbar-dark  "
        expand="md"
      >
        <Container fluid className="px-4">
          <NavbarBrand>
            {/* <img alt="logo" className="navbar-brand-img logo" src={logo} /> */}
          </NavbarBrand>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
