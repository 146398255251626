import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Container,
  Label,
  Card,
} from "reactstrap";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";
import Header from "../../components/Headers/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const QuestionForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const { row } = location.state || {};
  const navigate = useNavigate();
  const { accessToken, logout } = useContext(UserContext);
  const [formData, setFormData] = useState({
    question: "",
    status: "Active",
    category: "",
    governance_score: "",
    overall_score: "",
    field_type: "single",
    answers: [
      {
        input_type: "radio",
        options: "",
        is_required: false,
        placeholder: "",
        icon: "",
        dont_know: false,
      },
    ],
  });
  const [numFields, setNumFields] = useState(1);
  const categories = ["governance", "strategy", "operations"]; //, "testing"
  const fieldTypes = ["single"];
  const inputicons = ["%"];
  const inputTypes = ["radio", "input", "dropdown"];
  const [errors, setErrors] = useState({});

  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;

  const headers = {
    "content-type": "application/json",
    "auth-token": authToken,
    authorization: accessToken,
  };

  useEffect(() => {
    if (id) {
      setFormData(row);
    }
  }, [id, row]);

  const validate = () => {
    const newErrors = {};

    if (!formData.question) {
      newErrors.question = "Question is required.";
    }
    if (!formData.status) newErrors.status = "Status is required.";

    if (!formData.category) {
      newErrors.category = "Category is required.";
    }

    if (!formData.governance_score) {
      newErrors.governance_score = "Governance score is required.";
    } else if (!/^\d+(\.\d+)?$/.test(formData.governance_score)) {
      newErrors.governance_score =
        "Governance score is invalid. Please enter a valid decimal number.";
    }

    if (!formData.overall_score) {
      newErrors.overall_score = "Overall score is required.";
    } else if (!/^\d+(\.\d+)?$/.test(formData.overall_score)) {
      newErrors.overall_score =
        "Overall score is invalid. Please enter a valid decimal number.";
    }

    if (!formData.field_type) {
      newErrors.field_type = "Field type is required.";
    }

    if (
      formData.answers[0].input_type === "input" &&
      !formData.answers[0].placeholder
    ) {
      newErrors.placeholder = "Placeholder is required for input type.";
    }

    if (
      formData.answers[0].input_type === "radio" &&
      !formData.answers[0].options
    ) {
      newErrors.options = "Options is required for input type.";
    }

    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "governance_score" || name === "overall_score") {
      const numericValue = value.replace(/[^0-9.]|(?<=\..*)\./g, "");
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({ ...formData, [name]: value });
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleAnswerChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    const newAnswers = [...formData.answers];
    newAnswers[index][name] = type === "checkbox" ? checked : value;
    setFormData({ ...formData, answers: newAnswers });
  };

  const handleFieldTypeChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, field_type: value });

    if (value === "multiple") {
      setNumFields(1);
      setFormData((prevState) => ({
        ...prevState,
        answers: [
          {
            input_type: "radio",
            options: "",
            is_required: true,
            placeholder: "",
            icon: "",
            dont_know: false,
          },
        ],
      }));
    } else {
      setNumFields(1);
      setFormData((prevState) => ({
        ...prevState,
        answers: [
          {
            input_type: "radio",
            options: "",
            is_required: true,
            placeholder: "",
            icon: "",
            dont_know: false,
          },
        ],
      }));
    }
  };

  const handleNumFieldsChange = (e) => {
    const { value } = e.target;
    // setNumFields(Number(formData.answers.length??value));
    setNumFields(Number(value));
    setFormData((prevState) => ({
      ...prevState,
      answers: Array.from({ length: value }, () => ({
        input_type: "radio",
        options: "",
        is_required: true,
        placeholder: "",
        icon: "",
        dont_know: false,
      })),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      let res;
      if (id) {
        res = await APIServices.post(
          `/admin/settings/question_update`,
          formData,
          headers
        );
      } else {
        res = await APIServices.post(
          "/admin/settings/question_add",
          formData,
          headers
        );
      }

      if (res?.data?.status === true) {
        showToast(res?.data?.message || "Success", "success");
        setTimeout(() => navigate("/admin/questions-manage"), 2000);
      } else if (res?.status === 401) {
        logout();
        navigate("/");
      } else {
        showToast(res?.data?.message || "Something went wrong.", "error");
      }
    } catch (error) {
      console.error("API call error:", error);
      showToast("An unexpected error occurred. Please try again.", "error");
    }
  };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <>
      <Header />
      <Container className="form-upper" fluid>
        <Form className="mt-5 card_border shadow" onSubmit={handleSubmit}>
          <div className="form-header1">
            <h1
              className="
            card-title"
            >
              {id ? "Edit" : "Add"} Question
            </h1>
          </div>
          <FormGroup>
            <Label className="form-control-label required">Question</Label>
            <Input
              type="text"
              name="question"
              placeholder="Enter question"
              value={formData.question}
              onChange={handleChange}
            />
            {errors.question && (
              <div className="text-danger">{errors.question}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label
              className="form-control-label required"
              htmlFor="input-title"
            >
              Status
            </Label>
            <Input
              type="select"
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Input>
            {errors.status && (
              <div className="text-danger">{errors.status}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label className="form-control-label required">Category</Label>
            <Input
              type="select"
              name="category"
              value={formData.category}
              onChange={handleChange}
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </option>
              ))}
            </Input>
            {errors.category && (
              <div className="text-danger">{errors.category}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label className="form-control-label required">
              Governance Score
            </Label>
            <Input
              type="text"
              name="governance_score"
              placeholder="Enter governance score"
              value={formData.governance_score}
              onChange={handleChange}
              maxLength={6}
            />
            {errors.governance_score && (
              <div className="text-danger">{errors.governance_score}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label className="form-control-label required">Overall Score</Label>
            <Input
              type="text"
              name="overall_score"
              placeholder="Enter overall score"
              value={formData.overall_score}
              onChange={handleChange}
              maxLength={6}
            />
            {errors.overall_score && (
              <div className="text-danger">{errors.overall_score}</div>
            )}
          </FormGroup>
          {/* <FormGroup>
            <Label className="form-control-label required">Field Type</Label>
            <Input
              type="text"
              name="field_type"
              readOnly
              value={formData.field_type}
              onChange={handleFieldTypeChange}
            > */}
          {/* {fieldTypes.map((type) => (
                <option key={type} value={type}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </option>
              ))} */}
          {/* </Input>
            {errors.field_type && (
              <div className="text-danger">{errors.field_type}</div>
            )}
          </FormGroup> */}
          {/* {formData.field_type === "multiple" && (
            <FormGroup>
              <Label className="form-control-label required">
                Number of Fields
              </Label>
              {/* <Input
                type="number"
                name="numFields"
                min="1"
                max="5"
                value={numFields}
                onChange={handleNumFieldsChange}
                onWheel={(e) => e.target.blur()} // Prevents changing the value on scroll
              /> */}

          {/*  <Input
              // className="select-input"
              type="select"
              name="numFields"
              value={formData.answers.length||numFields}
              onChange={handleNumFieldsChange}
            >
              <option value="">Select Role</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Input>
            </FormGroup>
          )} */}

          {formData.answers.map((answer, index) => (
            <Card className="answer-shadow p-3 mb-3">
              <div key={index} className="mb-3">
                <FormGroup>
                  <Label className="form-control-label required">
                    Answer Type
                  </Label>
                  <Input
                    type="select"
                    name="input_type"
                    value={answer.input_type}
                    onChange={(e) => handleAnswerChange(index, e)}
                  >
                    {inputTypes.map((inputType) => (
                      <option key={inputType} value={inputType}>
                        {inputType.charAt(0).toUpperCase() + inputType.slice(1)}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                {answer.input_type === "radio" && (
                  <>
                    <FormGroup>
                      <Label className="form-control-label required">
                        Options
                      </Label>
                      <Input
                        type="text"
                        name="options"
                        placeholder="Enter comma-separated options"
                        value={answer.options}
                        onChange={(e) => handleAnswerChange(index, e)}
                      />
                      {errors.options && (
                        <div className="text-danger">{errors.options}</div>
                      )}
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="dont_know"
                          checked={answer.dont_know}
                          onChange={(e) => handleAnswerChange(index, e)}
                        />
                        Don't Know
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="is_required"
                          checked={answer.is_required}
                          onChange={(e) => handleAnswerChange(index, e)}
                        />
                        Required
                      </Label>
                    </FormGroup>
                  </>
                )}
                {answer.input_type === "input" && (
                  <>
                    <FormGroup>
                      <Label className="form-control-label required">
                        Placeholder
                      </Label>
                      <Input
                        type="text"
                        name="placeholder"
                        placeholder="Enter placeholder"
                        value={answer.placeholder}
                        onChange={(e) => handleAnswerChange(index, e)}
                      />
                      {errors.placeholder && (
                        <div className="text-danger">{errors.placeholder}</div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-control-label ">Icon</Label>
                      <Input
                        type="select"
                        name="icon"
                        placeholder="Enter icon"
                        value={answer.icon}
                        onChange={(e) => handleAnswerChange(index, e)}
                      >
                        <option value="">Select Icon</option>
                        {inputicons.map((icon) => (
                          <option key={icon} value={icon}>
                            {icon}
                          </option>
                        ))}
                      </Input>
                      {errors.icon && (
                        <div className="text-danger">{errors.icon}</div>
                      )}
                    </FormGroup>

                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="dont_know"
                          checked={answer.dont_know}
                          onChange={(e) => handleAnswerChange(index, e)}
                        />
                        Don't Know
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="is_required"
                          checked={answer.is_required}
                          onChange={(e) => handleAnswerChange(index, e)}
                        />
                        Required
                      </Label>
                    </FormGroup>
                  </>
                )}
                {answer.input_type === "dropdown" && (
                  <>
                    <FormGroup>
                      <Label className="form-control-label">Options</Label>
                      <Input
                        type="text"
                        name="options"
                        placeholder="Enter comma-separated options"
                        value={answer.options}
                        onChange={(e) => handleAnswerChange(index, e)}
                      />
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="dont_know"
                          checked={answer.dont_know}
                          onChange={(e) => handleAnswerChange(index, e)}
                        />
                        Don't Know
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="is_required"
                          checked={answer.is_required}
                          onChange={(e) => handleAnswerChange(index, e)}
                        />
                        Required
                      </Label>
                    </FormGroup>
                  </>
                )}
              </div>
            </Card>
          ))}

          <Button color="primary" type="submit">
            {id ? "Save" : "Submit"}
          </Button>
          <Button
            color="light"
            onClick={() => navigate("/admin/questions-manage")}
          >
            Back
          </Button>
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
};

export default QuestionForm;
