// Faq.js
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  DropdownToggle,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  Input,
  Tooltip,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import PaginatedTable from "../../components/Table/PaginatedTable";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { capitalizeFirstLetter, setCookies } from "../../utils/Helper";
import useMenuPermissions from "../../components/Customhook/custompermission";
import Notauthorized from "../../components/Not-authorized/notauthorized";

const Questionmanage = () => {
  const navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };
  const limit = 10;

  const [data1, setData1] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dropdownRef = useRef(false);
  const categorydropdownRef = useRef(false);
  const statusRef = useRef("Select Status");
  const categoryRef = useRef("operations");
  const offset = (currentPage - 1) * limit;
  const paginatedData = data1.slice(offset, offset + limit);
  const totalPages = Math.ceil(data1.length / limit);
  const [reset, setReset] = useState(false);
  const permissionscontext = useMenuPermissions('questions-manage');

  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const toggleDropdown = () => {
    dropdownRef.current = !dropdownRef.current;
    setData1((prev) => [...prev]);
  };

  const togglecateoryDropdown = () => {
    categorydropdownRef.current = !categorydropdownRef.current;
    setData1((prev) => [...prev]);
  };

  const fetchData = async (page = 1) => {
    const skip = (page - 1) * limit;
    const encodedStatus =
      statusRef.current === "Select Status" ? "" : statusRef.current;
    const encodedCategory =
      categoryRef.current === "Select Categories" ? "" : categoryRef.current;
    try {
      // &skip=${skip}&limit=${limit}
      const response = await APIServices.get(
        `/admin/settings/question_list?category=${encodedCategory}`,
        headers
      );
      if (response?.status === 200) {
        const data1 = response?.data?.list || [];
        setData1(data1);
        setTotalCount(response?.data?.list?.length || 0);
      } else if(response?.status === 401){
        logout();
        navigate("/");
       }else {
        setData1([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData1([]);
      setTotalCount(0);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
    // fetchRole()
  }, [currentPage]);

  
  const handleSelect = (status) => {
    statusRef.current = status;
  };
  const handleCategory = (category) => {
    categoryRef.current = category;
  };

  const refreshData = () => {
    setCurrentPage(1);
    fetchData(1);
  };

  const handleReset = () => {
    handleSelect("Select Status");
    handleCategory("operations");
    setReset(true);
  };

  useEffect(() => {
    if (reset) {
      fetchData();
      setCurrentPage(1);
      setReset(false);
    }
  }, [reset]);

  

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  const columns = [
    {
      dataField: "index",
      text: "S.No.",
      formatter: (cell, row, rowIndex) => {
        const page = currentPage; // Replace with the actual way to get the current page number
        const sizePerPage = limit; // Replace with the actual way to get rows per page
        return (page - 1) * sizePerPage + rowIndex + 1;
      },
    },

    {
      dataField: "Question",
      text: "Question",
      formatter: (cell, row) => (
        <div>
          <span id={`questionTooltip-${row.id}`}>
            {capitalizeFirstLetter(row.question)}
          </span>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen[`questionTooltip-${row.id}`]}
            target={`questionTooltip-${row.id}`}
            toggle={() => toggleTooltip(`questionTooltip-${row.id}`)}
          >
            {row.question}
          </Tooltip>
        </div>
      ),
    },
    {
      dataField: "Governance score",
      text: "Governance score",
      formatter: (cell, row) => row.governance_score,
    },
    {
      dataField: "Overall score",
      text: "Overall score",
      formatter: (cell, row) => row.overall_score,
    },
    {
      dataField: "Edit",
      text: "Edit",
      formatter: (cell, row, rowIndex) => (
        <Button
          color="light"
          onClick={() =>
            navigate(`/admin/questions-manage/update/${row.id}`, {
              state: { row },
            })
          }
          size="sm"
          className="light"
          disabled={!permissionscontext.edit}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
    {permissionscontext.view ? <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow faq-table">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0 card-title">Manage Questions</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      className="btn-theme1"
                      color="primary"
                      onClick={() => navigate("/admin/questions-manage/add")}
                      size="sm"
                      disabled={!permissionscontext.add}
                    >
                      Add
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <div className="select-filter-wrapper mb-0">
                <ButtonDropdown
                  className="dropdown-btn-wrapper"
                  isOpen={categorydropdownRef.current}
                  toggle={togglecateoryDropdown}
                >
                  {/* className="btn-default" */}
                  <DropdownToggle className="dropdown-btn select-input" caret>
                    {categoryRef.current}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => handleCategory("Select Categories")}
                    >
                      Select Categories
                    </DropdownItem>
                    <DropdownItem onClick={() => handleCategory("operations")}>
                      operations
                    </DropdownItem>
                    <DropdownItem onClick={() => handleCategory("strategy")}>
                      strategy
                    </DropdownItem>
                    <DropdownItem onClick={() => handleCategory("governance")}>
                      governance
                    </DropdownItem>
                    {/* <DropdownItem onClick={() => handleCategory("testing")}>
                      testing
                    </DropdownItem> */}
                  </DropdownMenu>
                </ButtonDropdown>
                <Button
                  color="primary"
                  onClick={(e) => refreshData(e)}
                  size="md"
                  className="filter mr-0"
                >
                  <i className="fa fa-filter"></i> Filter
                  {/* <i className="fa fa-refresh fa-spin"></i>  */}
                </Button>
                <Button
                  color="light"
                  onClick={(e) => handleReset(e)}
                  size="md"
                  className="filter"
                >
                  <i className="fa fa-reset"></i> Reset
                </Button>
              </div>
              {paginatedData &&
              Array.isArray(paginatedData) &&
              paginatedData.length !== 0 ? (
                <PaginatedTable
                  data={paginatedData}
                  columns={columns}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              ) : (
                <h2 className="text-center mb-4">Not Found..</h2>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
      </>: (
       <Notauthorized />
      )}
    </>
  );
};

export default Questionmanage;
