// Faq.js
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  DropdownToggle,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  Input,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import PaginatedTable from "../../components/Table/PaginatedTable";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";
import DeleteModal from "../../components/Modal/deleteModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { capitalizeFirstLetter } from "../../utils/Helper";
import useMenuPermissions from "../../components/Customhook/custompermission";
import Notauthorized from "../../components/Not-authorized/notauthorized";

const Subadmin = () => {
  const navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };
  const limit = 10;

  const [data1, setData1] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [categories, setCategories] = useState([]);
  const [roleid, setRoleid] = useState("");
  const [showDt, setShowDt] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const dropdownRef = useRef(false);
  const statusRef = useRef("Select Status");
  const offset = (currentPage - 1) * limit;
  const paginatedData = data1.slice(offset, offset + limit);
  const totalPages = Math.ceil(data1.length / limit);
  const [reset, setReset] = useState(false);
  const permissionscontext = useMenuPermissions('subadmin');

  const toggleDropdown = () => {
    dropdownRef.current = !dropdownRef.current;
    setData1((prev) => [...prev]);
  };

  const fetchData = async (page = 1) => {
    const skip = (page - 1) * limit;
    const encodedStatus =
      statusRef.current === "Select Status" ? "" : statusRef.current;
    try {
      // &skip=${skip}&limit=${limit}
      const response = await APIServices.get(
        `/admin/auth/admin_list?name=${fullname}&email=${email}&role_id=${roleid}&status=${encodedStatus}&offset=${skip}&limit=${limit}`,
        headers
      );
      if (response?.status === 200) {
        const data1 = response?.data?.list || [];
        setData1(data1);
        setTotalCount(response?.data?.list?.length || 0);
      } else if(response?.status === 401){
        logout();
        navigate("/");
       }else {
        setData1([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData1([]);
      setTotalCount(0);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
    fetchRole();
  }, [currentPage]);

  
  const handleSelect = (status) => {
    statusRef.current = status;
  };

  const refreshData = () => {
    setCurrentPage(1);
    fetchData(1);
  };

  const handleReset = () => {
    setFullname("");
    setEmail("");
    setRoleid("");
    handleSelect("Select Status");
    setReset(true);
  };

  useEffect(() => {
    if (reset) {
      fetchData();
      setCurrentPage(1);
      setReset(false);
    }
  }, [reset]);

  const handleDeleteFaq = async () => {
    try {
      if (selectedFaq !== null) {
        const payload = { id: selectedFaq };
        const res = await APIServices.post(
          `/admin/auth/admin_delete`,
          payload,
          headers
        );
        if (res?.data?.status === true) {
          showToast(res?.data?.message || "Success", "success");
          const updatedFaqs = data1.filter((faq) => faq.id !== selectedFaq);
          setData1(updatedFaqs);

          // Reset states
          setSelectedFaq(null);
        } else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
        // setAlertOpen(true);
      }
      setShowDt(false);
    } catch (error) {
      console.error("Error deleting FAQ:", error);
    }
  };

  const handleStatusChange = async (faq) => {
    const newStatus = faq.status === "Active" ? "Inactive" : "Active";
    try {
      const payload = { id: faq.id, status: newStatus };
      const res = await APIServices.post(
        `/admin/auth/admin_status_update`,
        payload,
        headers
      );
      if (res?.data?.status === true) {
        showToast(res?.data?.message || "Success", "success");
        const updatedFaqs = data1.map((item) =>
          item.id === faq.id ? { ...item, status: newStatus } : item
        );
        setData1(updatedFaqs);
      } else {
        showToast(res?.data?.message || "Something went wrong.", "error");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      showToast("Error updating status", "error");
    }
  };

  const fetchRole = async () => {
    try {
      const response = await APIServices.get(
        `/admin/auth/role_list?limit=100`,
        headers
      );
      const data = await response?.data?.list;
      setCategories(data);
      if (response?.status === 200) {
        const data = response?.data?.list || [];
        setCategories(data);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  const columns = [
    {
      dataField: "index",
      text: "S.No.",
      formatter: (cell, row, rowIndex) => {
        const page = currentPage; // Replace with the actual way to get the current page number
        const sizePerPage = limit; // Replace with the actual way to get rows per page
        return (page - 1) * sizePerPage + rowIndex + 1;
      },
    },
    {
      dataField: "profile_pic", // Assuming this field contains the image URL
      text: "Profile Picture",
      formatter: (cell, row) => (
        <img
          src={row.profile_pic} // URL to the image
          alt="Profile"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }} // Example styling
        />
      ),
    },
    {
      dataField: "fullname",
      text: "Fullname",
      formatter: (cell, row) => capitalizeFirstLetter(row.fullname),
    },
    {
      dataField: "username",
      text: "Username",
      formatter: (cell, row) => row.username,
    },
    {
      dataField: "Role",
      text: "Role",
      formatter: (cell, row) => {
        const category = categories.find(category => category.id == row.role_id);
        return category && category.name ; // Return the category name if found, otherwise "Unknown"
      },
    },
    {
      dataField: "mobile",
      text: "Mobile",
      formatter: (cell, row) => row.mobile,
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell, row) => row.email,
    },

    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <Button
          color={row.status === "Active" ? "success" : "warning"}
          onClick={() => handleStatusChange(row)}
          size="sm"
          className=""
          disabled={!permissionscontext.edit}
        >
          {row.status === "Active" ? "Active" : "Inactive"}
        </Button>
      ),
    },
    {
      dataField: "Edit",
      text: "Edit",
      formatter: (cell, row, rowIndex) => (
        <Button
          color="light"
          onClick={() =>
            navigate(`/admin/subadmin/update/${row.id}`, {
              state: { row },
            })
          }
          size="sm"
          className="light"
          disabled={!permissionscontext.edit}
        >
          Edit
        </Button>
      ),
    },
    {
      dataField: "Delete",
      text: "Delete",
      formatter: (cell, row, rowIndex) => (
        <Button
          color="danger"
          onClick={() => {
            setSelectedFaq(row.id);
            setShowDt(true);
          }}
          size="sm"
          className=""
          disabled={!permissionscontext.delete}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
    {permissionscontext.view ? <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0 card-title">Manage admin</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      className="btn-theme1"
                      color="primary"
                      onClick={() => navigate("/admin/subadmin/add")}
                      size="sm"
                      disabled={!permissionscontext.add}
                    >
                      Add
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <div className="select-filter-wrapper mb-0">
                <Input
                  className="w-25"
                  placeholder="Search by fullname"
                  value={fullname}
                  type="text"
                  onChange={(e) => setFullname(e.target.value)}
                />
                <Input
                  className="w-25"
                  placeholder="Search by email"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <select
                  className="select-input"
                  value={roleid}
                  onChange={(e) => setRoleid(e.target.value)}
                >
                  <option value="">Select Role</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="select-filter-wrapper">
                <ButtonDropdown
                  className="dropdown-btn-wrapper"
                  isOpen={dropdownRef.current}
                  toggle={toggleDropdown}
                >
                  {/* className="btn-default" */}
                  <DropdownToggle className="dropdown-btn select-input" caret>
                    {statusRef.current}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleSelect("Select Status")}>
                      Select Status
                    </DropdownItem>
                    <DropdownItem onClick={() => handleSelect("Active")}>
                      Active
                    </DropdownItem>
                    <DropdownItem onClick={() => handleSelect("Inactive")}>
                      Inactive
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>

                <Button
                  color="primary"
                  onClick={(e) => refreshData(e)}
                  size="md"
                  className="filter mr-0"
                >
                  <i className="fa fa-filter"></i> Filter
                  {/* <i className="fa fa-refresh fa-spin"></i>  */}
                </Button>
                <Button
                  color="light"
                  onClick={(e) => handleReset(e)}
                  size="md"
                  className="filter"
                >
                  <i className="fa fa-reset"></i> Reset
                </Button>
              </div>
              {paginatedData &&
              Array.isArray(paginatedData) &&
              paginatedData.length !== 0 ? (
                <PaginatedTable
                  data={paginatedData}
                  columns={columns}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              ) : (
                <h2 className="text-center mb-4">Not Found..</h2>
              )}
            </Card>
          </Col>
        </Row>
        <DeleteModal
          showdt={showDt}
          handleCloseDt={() => setShowDt(false)}
          handledelete={handleDeleteFaq}
        />
      </Container>
      <ToastContainer />
      </>: (
       <Notauthorized />
      )}
    </>
  );
};

export default Subadmin;
